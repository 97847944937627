import React from "react";
import { Badge, Skeleton, Button } from "antd";
import LottiePlayer from "lottie-react";
import { useInView } from "react-intersection-observer";
import { Paperclip, Image, Video, Shapes } from "lucide-react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import ListHeader from "../components/ListHeader";
import Threads from "../components/Threads";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import { gmailApi, Page } from "../api/gmail";

import { useUser } from "@/providers/userProvider";
import { useMyMail } from "@/providers/myMailContext";
import { useThread } from "@/providers/thredContext";

import { genrateInitials } from "@/helper/genrateInitials";

import emptyIboxLottie from "@/assets/lottis/empty-inbox.json";
import GmailPlusLottie from "@/assets/lottis/gmail-pulse.json";

import useHead from '@/hooks/useHead'

const colorSchemaForLabels: {
  [key: string]: { background: string; border: string };
} = {
  work: {
    background: "bg-blue-100",
    border: "border-blue-500",
  },
  application: {
    background: "bg-cyan-100",
    border: "border-cyan-500",
  },
  team_events: {
    background: "bg-purple-100",
    border: "border-purple-500",
  },
  promotions: {
    background: "bg-red-100",
    border: "border-red-500",
  },
  social: {
    background: "bg-yellow-100",
    border: "border-yellow-500",
  },
};

const AllMails = () => {
  const { getMessageList, connectGmail } = gmailApi();
  const { user } = useUser();
  const { setPage, page } = useMyMail();
  const { setThread, thread } = useThread();
  const { pathname } = useLocation();

  const path = pathname.split("/").pop();


  useHead({
    title: "Mailbox | " + path,
    description: "All your emails in one place",
  })

  const [pageQuery, setPageQuery] = React.useState<string>("INBOX");

  const { mutate, isPending } = useMutation({
    mutationKey: ["connectGmail"],
    mutationFn: connectGmail,
    onSuccess: (data) => {
      return (window.location.href = data.data.url);
    },
  });

  const {
    data,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    status,
    refetch,
  } = useInfiniteQuery<Page, Error>({
    queryKey: ["getAllMails", pageQuery],
    queryFn: async ({ pageParam = null }) => {
      const response = await getMessageList(
        pageParam as string | null,
        pageQuery
      );
      return response.data as Page;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.messages.nextPageToken) {
        return lastPage.messages.nextPageToken;
      }
      return null;
    },
    initialPageParam: null,
    enabled: !!user?.inbox?.inbox_account_email,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  React.useEffect(() => {
    if (path === "all-mails") {
      setPageQuery("INBOX");
      refetch();
    } else if (path === "sent") {
      setPageQuery("SENT");
      refetch();
    } else if (path === "spams") {
      setPageQuery("SPAM");
      refetch();
    } else if (path === "deleted") {
      setPageQuery("TRASH");
      refetch();
    }
  }, [path, refetch]);

  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, isFetchingNextPage, hasNextPage]);

  const newMessages = React.useMemo(() => {
    if (data?.pages) {
      return data.pages.flatMap((page) => page.messages.messages);
    }
    return [];
  }, [data?.pages]);

  const combinedMessages = React.useMemo(() => {
    if (data?.pages) {
      const existingMessagesMap = new Map(
        page.messages.messages.map((msg) => [msg?.threadId, msg])
      );

      return [
        ...existingMessagesMap.values(),
        ...newMessages.filter((msg) => !existingMessagesMap.has(msg?.threadId)),
      ];
    }
    return [];
  }, [newMessages, page.messages.messages, data?.pages]);

  React.useEffect(() => {
    if (data?.pages) {
      let filteredMessages = combinedMessages;
      const lastPage = data.pages[data.pages.length - 1];
      const nextPageToken = lastPage?.messages?.nextPageToken;
      if (pageQuery === "SENT") {
        filteredMessages = combinedMessages.filter((msg) =>
          msg.labelIds.includes("SENT")
        );
      }
      if (pageQuery === "SPAM") {
        filteredMessages = combinedMessages.filter((msg) =>
          msg.labelIds.includes("SPAM")
        );
      }
      if (pageQuery === "TRASH") {
        filteredMessages = combinedMessages.filter((msg) =>
          msg?.labelIds?.includes("TRASH")
        );
      }
      if (
        filteredMessages.length !== page.messages.messages.length ||
        nextPageToken !== page.messages.nextPageToken
      ) {
        setPage({
          messages: {
            messages: filteredMessages,
            nextPageToken: nextPageToken,
          },
          lastSyncDate: page.lastSyncDate || lastPage.lastSyncDate,
        });
      }
    }
  }, [
    data,
    combinedMessages,
    setPage,
    page.messages.messages.length,
    page.messages.nextPageToken,
    page.lastSyncDate,
    pageQuery,
  ]);

  return (
    <div className="flex items-center justify-between">
      <div className="relative min-w-[500px]">
        <div className="absolute top-0 left-0 w-full z-50">
          <ListHeader
            loading={isLoading}
            count={page?.messages.messages.length || 0}
            lastSyncDate={
              (page?.lastSyncDate as Date) || data?.pages[0].lastSyncDate
            }
          />
        </div>
        <div className="flex items-center min-w-[500px]">
          <div className="h-[calc(100vh-20px)] overflow-scroll py-[5px] pt-[63px] w-full scrollbar-visible overflow-x-hidden">
            {!user?.inbox?.inbox_account_email && (
              <div className="flex items-center justify-center gap-2 flex-col h-full">
                <LottiePlayer
                  animationData={GmailPlusLottie}
                  className="mx-auto w-full h-[300px]"
                />
                <p className="text-center text-gray-600">
                  {user?.is_main_account &&
                  user?.inbox?.inbox_account_email === null ? (
                    <>
                      You're one step away from effortlessly organizing <br />
                      all your communications. <br />
                      <Button
                        type="primary"
                        className="mt-4"
                        onClick={() => {
                          mutate();
                        }}
                        loading={isPending}
                      >
                        Connect Gmail
                      </Button>
                    </>
                  ) : user?.inbox?.inbox_account_email === null ? (
                    <>
                      Your organization does not have any active mailboxes!{" "}
                      <br /> Connect your support mailbox to Keeping to get
                      started.
                    </>
                  ) : (
                    <>
                      You're one step away from effortlessly organizing <br />
                      all your communications. <br />
                      <Button
                        type="primary"
                        className="mt-4"
                        onClick={() => {
                          mutate();
                        }}
                        loading={isPending}
                      >
                        Connect Gmail
                      </Button>
                    </>
                  )}
                </p>
              </div>
            )}
            {status === "pending" && isLoading && (
              <div className="p-4 mt-[-15px]">
                {Array.from({ length: 5 }).map((_, index) => (
                  <Skeleton avatar key={index} active title className="p-4" />
                ))}
              </div>
            )}

            {status === "success" && (
              <>
                {page.messages.messages.length === 0 ? (
                  <div className="flex flex-col items-center justify-center h-full gap-0">
                    <LottiePlayer
                      animationData={emptyIboxLottie}
                      loop={false}
                      autoplay
                      className="mx-auto w-full pl-10 h-[400px]"
                    />
                    <p className="text-center">Your inbox is empty</p>
                  </div>
                ) : null}
              </>
            )}

            {status === "success" &&
              page?.messages?.messages
                ?.sort(
                  (a, b) => Number(b.internalDate) - Number(a.internalDate)
                )
                .map((mail, index) => (
                  <div
                    key={mail?.id + index}
                    className={
                      mail?.threadId === thread?.thredId
                        ? "px-[20px] py-4 w-[500px] flex items-center gap-[10px] hover:shadow-lg rounded-sm border border-dashed border-blue-400 shadow-lg"
                        : "px-[20px] py-4 w-[500px] flex items-center gap-[10px] hover:shadow-lg border-b"
                    }
                    onClick={() => {
                      setThread({
                        thredId: mail?.threadId,
                      });
                    }}
                  >
                    <div className="flex gap-[10px]">
                      {/* <Checkbox /> */}
                      <Avatar className="h-[50px] w-[50px] text-[20px] drop-shadow-lg">
                        <AvatarImage
                          src={genrateInitials(
                            mail?.payload.headers
                              .find(
                                (header: { name: string; value: string }) =>
                                  header.name === "From"
                              )
                              ?.value.split("<")[0]
                              .replace(/"/g, "")
                              ?.charAt(0) as string
                          )}
                        />
                        <AvatarFallback
                          style={{ background: generateRandomColor() }}
                        >
                          <span>
                            {mail?.payload.headers &&
                              mail.payload?.headers
                                ?.find(
                                  (header: { name: string; value: string }) =>
                                    header.name === "From"
                                )
                                ?.value.split("<")[0]
                                .replace(/"/g, "")
                                .charAt(0)}
                          </span>
                        </AvatarFallback>
                      </Avatar>
                    </div>
                    <div className="flex flex-col gap-[3px]">
                      <div className="flex items-center justify-between ">
                        <h4 className="text-[15px] font-semibold flex items-center gap-1">
                          {mail?.labelIds.find(
                            (label: string) => label === "UNREAD"
                          ) && (
                            <Badge
                              color="red"
                              status="processing"
                              classNames={{ indicator: "mr-1" }}
                            />
                          )}

                          {
                            mail?.payload?.headers
                              .find(
                                (header: { name: string; value: string }) =>
                                  header.name === "From"
                              )
                              ?.value?.split("<")[0]
                          }

                          {/* count the threds */}
                          {mail?.threadCount > 1 && (
                            <Badge
                              status="success"
                              color="#a1a1a1"
                              classNames={{
                                root: "!rounded-md",
                                indicator: "!rounded-md",
                              }}
                              size="small"
                              rootClassName="text-sm"
                              count={mail.threadCount}
                            />
                          )}
                        </h4>
                        <span className="text-sm text-gray-500">
                          {convertDateToTime(mail?.internalDate)}
                        </span>
                      </div>
                      <p className="text-sm text-gray-700">
                        {
                          mail?.payload.headers.find(
                            (header: { name: string; value: string }) =>
                              header.name === "Subject"
                          )?.value
                        }
                      </p>

                      <p className="text-sm text-gray-500 text-ellipsis whitespace-nowrap w-[400px] overflow-hidden">
                        {mail?.snippet}
                      </p>
                      <div className="flex items-center justify-between mt-[10px]">
                        {mail?.attachments?.length &&
                        mail?.attachments.length > 0 ? (
                          <div className="flex gap-2">
                            {mail?.attachments.filter(
                              (att) => att.type === "pdf" || att.type === "docx"
                            ).length > 0 && (
                              <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                                <Paperclip size={16} />
                                <span className="text-sm text-gray-500">
                                  {
                                    mail.attachments.filter(
                                      (att) =>
                                        att.type === "pdf" ||
                                        att.type === "docx"
                                    ).length
                                  }
                                </span>
                              </div>
                            )}
                            {mail?.attachments.filter(
                              (att) => att.type === "image"
                            ).length > 0 && (
                              <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                                <Image size={16} />

                                <span className="text-sm text-gray-500">
                                  {
                                    mail?.attachments.filter(
                                      (att) => att.type === "image"
                                    ).length
                                  }
                                </span>
                              </div>
                            )}
                            {mail?.attachments.filter(
                              (att) => att.type === "video"
                            ).length > 0 && (
                              <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                                <Video size={16} />
                                <span className="text-sm text-gray-500">
                                  {
                                    mail?.attachments.filter(
                                      (att) => att.type === "video"
                                    ).length
                                  }
                                </span>
                              </div>
                            )}
                            {mail?.attachments.filter(
                              (att) => att.type === "other"
                            ).length > 0 && (
                              <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                                <Shapes size={16} />
                                <span className="text-sm text-gray-500">
                                  {
                                    mail.attachments.filter(
                                      (att) => att.type === "other"
                                    ).length
                                  }
                                </span>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div />
                        )}
                        <div className="flex items-center gap-2">
                          <span
                            key={mail?.id}
                            className={`text-sm flex items-center gap-[5px] text-gray-700 capitalize px-[10px] py-1 rounded-md 
                          ${
                            colorSchemaForLabels[
                              mail?.labelIds.filter(
                                (label: string) => label !== "UNREAD"
                              )[0]
                            ]?.background
                          } `}
                          >
                            <div
                              key={mail?.id + index}
                              className={
                                "h-[12px] w-[12px] rounded-md border-2 " +
                                colorSchemaForLabels[
                                  mail?.labelIds.filter(
                                    (label: string) => label !== "UNREAD"
                                  )[0]
                                ]?.border
                              }
                            />
                            {mail?.labelIds.map((label: string) => (
                              <span className="text-xs">
                                {label.replace("Label_", "").toLowerCase()}
                              </span>
                            ))}
                          </span>
                          {/* <span className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-md text-center">
                        {10}
                      </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            {status === "success" && (
              <div ref={ref}>
                {isFetchingNextPage && (
                  <div className="w-full flex items-center justify-center px-[40px] py-[10px]">
                    <Skeleton avatar active title />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full bg-[#fbfbfb]">
        <Threads />
      </div>
    </div>
  );
};

export default AllMails;

const generateRandomColor = () => {
  return `hsl(${Math.floor(Math.random() * 360)}, 50%, 90%)`;
};

const convertDateToTime = (timeStamp: string) => {
  const newDate = new Date(Number(timeStamp));

  // if the date is today return Today
  if (newDate.toDateString() === new Date().toDateString()) {
    return (
      "Today" +
      ", " +
      newDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        formatMatcher: "best fit",
      })
    );
  }
  return newDate.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    formatMatcher: "best fit",
  });
};
