import http from "@/http";

const useNotificationApi = () => {
  const getNotifications = () => {
    return http.get("/releasenote/get-all-notifications");
  };
  return { getNotifications };
};


export default useNotificationApi;