import React from "react";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  message,
  // Popover,
  Button,
} from "antd";
import { useMutation } from "@tanstack/react-query";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
// import { Slider } from "@/components/ui/slider";
// import { Checkbox } from "@/components/ui/checkbox";

import { Datatype } from "@/types/schema";

import useWorkflowSchema from "../hooks/useWorkflowSchema";

import { MdOutlineLabel } from "react-icons/md";
import { IoPin } from "react-icons/io5";
import {
  AlertTriangle,
  // BadgeInfo
} from "lucide-react";

import { DataTypes } from "./Datatype";

const datatypes = [
  {
    value: "date",
    label: "Date",
  },
  {
    value: "string",
    label: "string",
  },
  {
    value: "integer",
    label: "Integer",
  },
];

const dateFormats = [
  {
    value: "YYYY-MM-DD",
    label: "YYYY-MM-DD",
  },
  {
    value: "YYYY/MM/DD",
    label: "YYYY/MM/DD",
  },
  {
    value: "MM-DD-YYYY",
    label: "MM-DD-YYYY",
  },
  {
    value: "DD/MM/YYYY",
    label: "DD/MM/YYYY",
  },
];

interface Props {
  showUpdateSchema: boolean;
  setShowUpdateSchema: React.Dispatch<React.SetStateAction<boolean>>;
  schemaInfo: Datatype;
  refetch: () => void;
  schema: string;
  sectionName: string;
}

interface FormData {
  label: string;
  desc: string;
  id: string;
  data_type: string;
  is_visible?: boolean;
  date_format?: string | null;
  question: string;
}

export const UpdateSchema = ({
  showUpdateSchema,
  schemaInfo,
  refetch,
  setShowUpdateSchema,
  schema,
  sectionName,
}: Props) => {
  const { pathname } = useLocation();
  const { updateWorkflowField } = useWorkflowSchema();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue: setFormValue,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      desc: schemaInfo?.description || "",
      label: schemaInfo?.label || "",
      id: schemaInfo?.id || "unknown_id",
      data_type: schemaInfo?.data_type || "string",
      date_format: "" || null,
      question: schemaInfo?.question as string,
    },
    mode: "onSubmit",
    resolver: yupResolver(
      Yup.object().shape({
        label: Yup.string().required("Label is required"),
        desc: Yup.string().required("Description is required"),
        id: Yup.string().required("ID is required"),
        data_type: Yup.string().required("Data type is required"),
        date_format: Yup.string().optional().nullable(),
        question: Yup.string().required("Question is required"),
      })
    ),
  });

  React.useEffect(() => {
    setFormValue("label", schemaInfo?.label);
    setFormValue("desc", schemaInfo?.description as string);
    setFormValue("id", schemaInfo?.id);
    setFormValue("data_type", schemaInfo?.data_type);
    setFormValue("date_format", schemaInfo?.date_format || null);
    setFormValue("question", schemaInfo?.question as string);
  }, [schemaInfo, setFormValue]);

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["updateWorkflowField"],
    mutationFn: updateWorkflowField,
    onError: (err: AxiosError) => {
      const errorMessage = (err.response?.data as { message: string })?.message;
      message.error(errorMessage);
    },
    onSuccess: () => {
      message.open({
        content: "Updating schema...",
        key: "updateSchema",
        type: "loading",
      });
      refetch();
      setShowUpdateSchema(false);
      reset();
      message.destroy("updateSchema");
      message.success("Schema updated successfully");
    },
  });

  const onSubmit = (data: FormData) => {
    mutateAsync({
      workflowId: pathname.split("/").pop() as string,
      section: sectionName,
      id: schemaInfo?.id || "unknown_id",
      data: {
        label: data.label,
        description: data.desc,
        id: schemaInfo?.id || "id",
        section: sectionName,
        data_type: data?.data_type,
        date_format: data?.date_format as string,
        question: data?.question,
        is_visible: schemaInfo?.is_visible,
      },
    });
  };

  return (
    <Sheet
      open={showUpdateSchema}
      onOpenChange={() => setShowUpdateSchema(false)}
      key={schemaInfo?.id}
    >
      <SheetContent className="min-w-[450px]">
        <SheetHeader>
          <SheetTitle>Update Schema for {schema}</SheetTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-[10px] mt-[10px]">
              <div>
                <Label className="text-gray-600 text-[13px] dark:text-gray-200">
                  ID
                </Label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="w-full mt-[5px]"
                      name="id"
                      placeholder="Enter ID"
                      error={errors.id?.message}
                      disabled
                      addornment={<IoPin className="h-[20px]" />}
                    />
                  )}
                  name="id"
                />
              </div>
              <div>
                <Label className="text-gray-600 text-[13px] dark:text-gray-200">
                  Label
                </Label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="w-full mt-[5px]"
                      name="label"
                      placeholder="Enter label"
                      error={errors.label?.message}
                      addornment={<MdOutlineLabel className="h-[20px]" />}
                    />
                  )}
                  name="label"
                />
              </div>
              {/* <div>
                <Label className="text-gray-600 text-[13px] dark:text-gray-200 flex justify-between items-center">
                  Ask *
                  <Popover
                    content={
                      <div>
                        Ask is the question that you want to ask the AI to fill
                        the field. <br />
                        This will help the AI to understand what you want from
                        the document.
                        <br /> <br />
                        <b>Best practice to write the perfect question</b>{" "}
                        <br />
                        1. Be specific <br />
                        2. Be clear <br />
                        3. Try small sentance <br />
                        <br />
                        exmple 1: What is the invoice number ? <br />
                        example 2: What is the total amount ? <br />
                      </div>
                    }
                    title="Ask ?"
                    trigger={"hover"}
                  >
                    <BadgeInfo
                      size={15}
                      strokeWidth={1.2}
                      className="cursor-pointer"
                    />
                  </Popover>
                </Label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="like What is the invoice number ?"
                      className="mt-2"
                      name="question"
                      error={errors.question?.message}
                    />
                  )}
                  name="question"
                />
              </div> */}
              <div>
                <Label className="text-gray-600 text-[13px] dark:text-gray-200">
                  Description
                </Label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <textarea
                      {...field}
                      placeholder="short description of the field"
                      className="flex min-h-[100px] mt-2 !text-[13px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 max-h-[150px]"
                      name="desc"
                    />
                  )}
                  name="desc"
                />
                {errors.desc && (
                  <p className="text-[12px] text-red-500 dark:text-red-500 flex items-center gap-1 mt-[5px]">
                    <AlertTriangle size={13} />
                    {errors.desc.message}
                  </p>
                )}
              </div>
              <div>
                <Controller
                  control={control}
                  name="data_type"
                  render={({ field }) => (
                    <DataTypes
                      comp_name="date type"
                      data={datatypes}
                      {...field}
                    />
                  )}
                />
                {errors.data_type && (
                  <p className="text-[12px] text-red-500 dark:text-red-500 flex items-center gap-1 mt-[5px]">
                    <AlertTriangle size={13} />
                    {errors.data_type.message}
                  </p>
                )}
              </div>
              <div>
                {watch("data_type") === "date" && (
                  <Controller
                    control={control}
                    name="date_format"
                    render={({ field }) => (
                      <DataTypes
                        comp_name="date format"
                        data={dateFormats}
                        {...field}
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <div className="flex gap-[10px] mt-[15px]">
              <Button
                htmlType="submit"
                className="w-[100px]"
                type="primary"
                loading={isPending}
              >
                Update
              </Button>
            </div>
          </form>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};
