import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import React from "react";

import { useUser } from "@/providers/userProvider";

import { genrateInitials } from "@/helper/genrateInitials";

interface ThreadHeaderProps {
  subject: string;
  from: string;
  to: string;
  cc: string;
  threads: number;
}

const ThreadHeader: React.FC<ThreadHeaderProps> = ({
  subject,
  cc,
  from,
  threads,
  to,
}) => {
  const { user } = useUser();

  const yourEmail = to?.split("<")[1]?.split(">")[0] || to;

  const [destination, setDestination] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (user?.email === yourEmail) {
      setDestination(
        `You, ${to
          ?.split(",")
          .map((email) => email)
          ?.slice(1)
          ?.join(",")}`
      );
    }else{
      setDestination(`
        You
      `);
    }
  }, [to, user, yourEmail]);

  return (
    <div className="flex items-start justify-between border-b pb-[10px]">
      <div className="flex gap-2 items-center">
        <div>
          <Avatar className="h-[50px] w-[50px] text-[20px]">
            <AvatarImage
              src={genrateInitials(
                from?.split("<")[0]?.split(" ")[0].replace(/"/g, "")?.charAt(0)
              )}
            />
            <AvatarFallback>
              <span>
                {from
                  ?.split("<")[0]
                  ?.split(" ")[0]
                  .replace(/"/g, "")
                  ?.charAt(0)}
              </span>
            </AvatarFallback>
          </Avatar>
        </div>
        <div>
          <h4 className="font-semibold max-w-[700px]">
            {subject}
            <span className="font-normal text-[12px] text-gray-500 ml-2">
              {from}
            </span>
          </h4>
          <div className="flex gap-[20px]">
            <div>
              <span className="text-gray-500 text-[12px]">To: </span>
              <span className="text-[12px] font-semibold">
                {destination === "You, " ? "You" : destination}
              </span>
            </div>
            {cc && (
              <div>
                <span className="text-gray-500 text-[12px]">Cc: </span>
                <span className="text-[12px] font-semibold">{cc}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <p className="text-gray-500 text-sm">{threads} threads</p>
      </div>
    </div>
  );
};

export default ThreadHeader;
