import http from "@/http";

interface ApproverResponse {
  email: string;
  name: string;
  role: string;
  _id: string;
}

interface AppliedResponse {
  name: string;
  desc: string;
  line_item: boolean;
}

export interface StageTypes {
  id: number;
  name: string;
  open: boolean;
  isEditing: boolean;
  reviewer: string;
  openReviewer: boolean;
  appliedTo: string;
  openFlagCondition: boolean;
  flagCondition: number;
  condition: string;
}

interface ApprovalRule {
  stages: StageTypes[];
  workflow_id: string;
  info: {
    rule_name: string;
    description: string;
  };
}

interface CreateApproval {
  rule: ApprovalRule;
  message: string;
}

export const useApprover = () => {
  const fetchApprovers = async () => {
    const data = await http.get<ApproverResponse[]>("/approval/get-approvers");
    return data;
  };

  const fetchApplied = async (workflow_id: string) => {
    const data = await http.get<AppliedResponse[]>(
      `/approval/get-applied/${workflow_id}`
    );
    return data;
  };

  const createApprovalRule = async ({ data }: { data: ApprovalRule }) => {
    return await http.post<CreateApproval>(
      "/approval/create-approval/" + data.workflow_id,
      data
    );
  };

  const getApprovalRule = async (workflow_id: string) => {
    return await http.get<ApprovalRule>(
      "/approval/get-approval-rule/" + workflow_id
    );
  };

  return { fetchApprovers, fetchApplied, createApprovalRule, getApprovalRule };
};
