import { Pencil, PlusCircle,Braces } from "lucide-react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import { useMutation } from "@tanstack/react-query";

import useWorkflowApi from "../hooks/useWorkflowSchema";
import { AxiosError } from "axios";

interface AddSectionProps {
  setCloseDialog: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: boolean;
  refetch: () => void;
  workflowId: string;
}

interface FormData {
  sectionName: string;
  newField: string;
}

const AddSection = ({
  closeDialog,
  refetch,
  setCloseDialog,
  workflowId,
}: AddSectionProps) => {
  const { addMoreSection } = useWorkflowApi();

  const schema = Yup.object().shape({
    sectionName: Yup.string().required("Section name is required"),
    newField: Yup.string().required("Field name is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      sectionName: "",
    },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { isPending, mutateAsync } = useMutation({
    mutationKey: ["addMoreSection"],
    mutationFn: addMoreSection,
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
    },
    onSuccess: (data) => {
      reset();
      refetch();
      setCloseDialog(false);
      message.success(data.data.message);
    },
  });

  const handleAddSection = (data: FormData) => {
    mutateAsync({ workflow_id: workflowId, section_name: data.sectionName, field_name: data.newField });
  };

  const onClose = () => {
    setCloseDialog(false);
    clearErrors("root");
  };

  return (
    <Dialog modal open={closeDialog} key={workflowId} onOpenChange={onClose}>
      <DialogContent>
        <form onSubmit={handleSubmit(handleAddSection)}>
          <DialogTitle className="text-gray-700">Add new section</DialogTitle>
          <DialogHeader>
            <DialogDescription>
              <div className="form-group mt-4">
                <label
                  htmlFor="sectionName"
                  className="text-gray-700 text-[13px]"
                >
                  What this section called ?
                </label>
                <Controller
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="mt-2"
                      placeholder="Enter section name"
                      error={errors.sectionName?.message}
                      addornment={<Pencil size={19} strokeWidth={1.2} />}
                    />
                  )}
                  name="sectionName"
                  control={control}
                />
              </div>
              <div className="form-group mt-4">
                <label
                  htmlFor="sectionName"
                  className="text-gray-700 text-[13px]"
                >
                  What field you want to add ? name it's (ID)*
                </label>
                <Controller
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="mt-2"
                      placeholder="Enter field name ID"
                      error={errors.newField?.message}
                      addornment={<Braces size={19} strokeWidth={1.2} />}
                    />
                  )}
                  name="newField"
                  control={control}
                />
              </div>
            </DialogDescription>
          </DialogHeader>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary flex gap-[10px]"
              loading={isPending}
            >
              <PlusCircle size={19} strokeWidth={1.2} />
              Add
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddSection;
