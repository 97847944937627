import { Workflow } from "@/types/workflow";
import { CalendarCheck } from "lucide-react";
import { Popover } from "antd";

import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

import { ColumnDef } from "@tanstack/react-table";

export const ConfigWorkflowDataColumns: ColumnDef<Workflow>[] = [
  {
    accessorKey: "item",
    header: () => <div className="text-left">Workflow type</div>,
    cell: ({ row }) => {
      return (
        <div className="text-gray-700 dark:text-gray-400">
          {row.original.workflow_type}
        </div>
      );
    },
  },
  {
    accessorKey: "workflow_name",
    header: () => <div className="text-left">Workflow name</div>,
    cell: ({ row }) => {
      const fileName = row.original.workflow_name;
      return <p className="text-blue-600">{fileName}</p>;
    },
  },
  {
    accessorKey: "createdAt",
    header: () => <div className="text-left ml-[50px]">Create at</div>,
    cell: ({ row }) => {
      const location = row.getValue("createdAt") as string;
      const date = new Date(location);
      return (
        <div className="items-center justify-start ml-[50px] flex gap-1 text-[#7c7c7c] dark:text-gray-400">
          <CalendarCheck color="gray" size={22} strokeWidth={1.2} />
          {date.getTime() > 0 ? (
            <>
              {date.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
                calendar: "gregory",
                numberingSystem: "latn",
                localeMatcher: "lookup",
                weekday: "short",
              })}
            </>
          ) : (
            "N/A"
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "Invoice count",
    header: () => <div className="text-left ml-[50px]">Number of invoice</div>,
    cell: ({ row }) => {
      const numberOfDocuments = row.original.number_of_documents;
      return (
        <div className="items-center justify-center ml-[-100px] flex gap-1 text-[#7c7c7c]">
          {numberOfDocuments}
        </div>
      );
    },
  },
  {
    accessorKey: "Manager",
    header: () => <div className="text-left">Manager</div>,
    cell: ({ row }) => {
      const manager = row.original?.owner_name;
      return (
        <div className="text-gray-700 dark:text-gray-400 capitalize flex items-center gap-2">
          <Avatar>
            <AvatarImage src={row.original.owner_image} className="pointer-events-none"/>
            <AvatarFallback>
             {
              row.original.owner_name.split(" ")?.map((name) => name[0])?.join("")
             }
            </AvatarFallback>
          </Avatar>
          {manager ? manager : "N/A"}
        </div>
      );
    },
  },
  {
    accessorKey: "workflow_description",
    header: () => <div className="text-left">Description</div>,
    cell: ({ row }) => {
      const description = row.original?.workflow_description;
      return (
        <div className="capitalize">
          <div className="capitalize">
          <Popover title={"workflow description"} content={description} trigger={"hover"} rootClassName="max-w-[500px]" placement="left">
          {
            description && description.length > 17 ? (
              <button className="text-gray-700 overflow-hidden text-left text-ellipsis whitespace-nowrap w-[120px] dark:text-gray-400">
                {description}
              </button>
            ) : (
              <div className="text-gray-700 overflow-hidden text-left text-ellipsis whitespace-nowrap w-[100px] dark:text-gray-400">
                {description ? description : "N/A"}
              </div>
            )
           }
           </Popover>
        </div>
        </div>
      );
    },
  },
];
