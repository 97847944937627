import React from "react";
import useHead from "@/hooks/useHead";
import { AxiosError } from "axios";
import {message} from 'antd'
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader2 } from "lucide-react";

import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import Text from "@/components/Text";

import coverImage from "@/assets/cover/flower.jpg";

import { appRoutes } from "@/utils/paths";

import { useUserDispatch } from "@/providers/userProvider";
import { useTokenDispatch, TokenActions } from "@/providers/useAuthProvider";

import { use2FA } from "./hooks/index";

const OTP = () => {
  useHead({
    title: "2FA Authentication | OpticalAI",
    description: "2FA Authentication",
  });

  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const tokenDispatch = useTokenDispatch();
  const [searchParams] = useSearchParams();

  const { verify2FA } = use2FA();

  React.useEffect(() => {
    if (
      searchParams.get("token") === undefined ||
      searchParams.get("token") === "" || 
      searchParams.get("token") === null
    ) {
      navigate(appRoutes.auth.MAIN_LOGIN);
    }
  }, [searchParams.get("token"), navigate]);

  const { mutate, isPending } = useMutation({
    mutationKey: ["verify2FA"],
    mutationFn: verify2FA,
    onSuccess: (data) => {
      dispatch?.setAccountId(data.data.user.userId);
      tokenDispatch({
        type: TokenActions.setTokens,
        payload: {
          access_token: data.data.access_token,
          refresh_token: data.data.refresh_token,
        },
      });
      message.success("Login successful!");
      return navigate(appRoutes.dashboard.WORKFLOWS.INDEX);
    },
    onError: (error:AxiosError) => {
      if((error.response?.data as {message: string}).message.toUpperCase() === String('User not found.').toUpperCase()){
        message.error("Token is invalid or expired. Please login again.");
        return navigate(appRoutes.auth.MAIN_LOGIN);
      }
      return message.error(
        ((error as AxiosError).response?.data as { message: string }).message);
    },
  });

  const handleChange = (otp: string) => {
    if (otp.length === 6) {
      mutate({
        token: otp,
        email: atob(searchParams.get("token") as string) || "",
      });
    }
  };

  return (
    <div
      className="flex items-center justify-center h-screen w-full bg-cover font-popins md:fixed mx-auto px-[10px]"
      style={{
        backgroundImage: `url(${coverImage})`,
        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Card className="w-full md:w-[400px] pt-10 px-5 bg-inherit bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border-none text-white transition-shadow shadow-2xl">
        <CardHeader>
          <CardTitle className="text-[25px]">
            <Text className="text-[48px] font-xeroda text-gray-700 dark:text-white mb-[20px] flex gap-[1px]">
              Optical <span className="text-blue-600">AI</span>
            </Text>
          </CardTitle>
          <CardDescription className="text-white text-[14px]">
            Open the authenticator app and type the code below
          </CardDescription>
        </CardHeader>
        <CardContent>
          {!isPending ? (
            <InputOTP
              autoFocus
              inputMode="numeric"
              onChange={handleChange}
              maxLength={6}
            >
              <InputOTPGroup>
                <InputOTPSlot
                  className="w-[50px] h-[50px] text-[20px]"
                  index={0}
                />
                <InputOTPSlot
                  className="w-[50px] h-[50px] text-[20px]"
                  index={1}
                />
                <InputOTPSlot
                  className="w-[50px] h-[50px] text-[20px]"
                  index={2}
                />
              </InputOTPGroup>
              <InputOTPSeparator />
              <InputOTPGroup>
                <InputOTPSlot
                  className="w-[50px] h-[50px] text-[20px]"
                  index={3}
                />
                <InputOTPSlot
                  className="w-[50px] h-[50px] text-[20px]"
                  index={4}
                />
                <InputOTPSlot
                  className="w-[50px] h-[50px] text-[20px]"
                  index={5}
                />
              </InputOTPGroup>
            </InputOTP>
          ) : (
            <Loader2
              size={40}
              strokeWidth={1.2}
              className="animate-spin my-2 mx-auto"
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default OTP;
