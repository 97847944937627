import { Checkbox, Button } from "antd";
import { useParams } from "react-router-dom";
import { Search, RefreshCcw, Loader2 } from "lucide-react";

import { timeAgo } from "@/helper/timAgo";

import useRefetchLatestEmail from "../hooks/useRefetchLatestEmail";

const ListHeader = ({
  count,
  lastSyncDate,
  loading,
}: {
  loading: boolean;
  count: number;
  lastSyncDate: Date;
}) => {
  const { isSyncMail, refetchLatestAllEmail } = useRefetchLatestEmail();
  const params = useParams();
  return (
    <div className="px-[20px] py-[15px] border-b bg-white max-w-full flex items-center justify-between ">
      <div className="flex gap-2 items-center">
        <Checkbox />
        <h4 className="text-[15px] font-semibold capitalize">
          {params?.inbox_type?.split("-").join(" ")}
        </h4>
        <span className="text-sm text-gray-500">{count} messages</span>
      </div>
      <div className="flex gap-2 items-center">
        {lastSyncDate === null ? null : (
          <>
            <p className="text-[10px] text-gray-400 uppercase flex gap-2 items-center justify-center">
              last sync
              {loading ? (
                <Loader2 size="12" className="text-gray-500 animate-spin" />
              ) : (
                <span className="lowercase font-semibold text-gray-600">
                  {timeAgo(lastSyncDate)}
                </span>
              )}
            </p>

            <Button
              onClick={refetchLatestAllEmail}
              type="text"
              icon={<RefreshCcw size="18" className="text-gray-500" />}
              loading={isSyncMail}
            />
            <Button
              type="text"
              icon={<Search size="18" className="text-gray-500" />}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ListHeader;
