import useHead from "@/hooks/useHead";

const Team = () => {

  useHead({
    title: "Team | OpticalAI",
    description: "Team for OpticalAI"
  });

  return  (
    <div>
        <h1 className="text-xl font-semibold">
        Team Manager
        </h1>
        <p className="text-[14px] text-[#7c7c7c]">Manage groups or account and permissions.</p>
        <div className="mt-[50px] text-center">
          <p>Comming soon...</p>
        </div>

    </div>
  )
};

export default Team;
