import React from "react";
import { useMyMail } from "@/providers/myMailContext";

import { gmailApi } from "../api/gmail";

const useRefetchLatestEmail = () => {
  const { syncMessageList } = gmailApi();
  const { setPage } = useMyMail();
  const [isSyncMail, setIsSyncMail] = React.useState(false);

  const refetchLatestAllEmail = async () => {
    setIsSyncMail(true);
    try {
      const latestResponse = await syncMessageList();
      const latestMessages = latestResponse.data.messages.messages;

      if (!Array.isArray(latestMessages)) {
        throw new Error("Invalid structure for latestMessage");
      }
      setPage({
        messages: {
          messages: latestMessages, // Replaced old messages with the latest ones
          nextPageToken: latestResponse.data.messages.nextPageToken,
        },
        lastSyncDate: latestResponse.data.lastSyncDate, // Update sync date
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsSyncMail(false);
    }
  };

  return {
    refetchLatestAllEmail,
    isSyncMail,
  };
};

export default useRefetchLatestEmail;
