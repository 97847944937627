import { Card } from '@tremor/react';

interface CardBoxProps {
  title: string;
  value: string | number;
}

const CardBox = ({title,value}:CardBoxProps) => {
  return (
    <Card
      className="max-w-[calc(92%/5)] min-w-[200px]"
      decoration="bottom"
      decorationColor="blue"
    >
      <p className="text-tremor-label text-tremor-content dark:text-gray-300">{title}</p>
      <p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">{value}</p>
    </Card>
  );
}

export default  CardBox