import Headnav from "@/components/Navbars/Headnav";
import useHead from "@/hooks/useHead";

import { WorkflowsTable } from "@/components/table/workflowsTable";

const Workflows = () => {
  useHead({
    title: "Workflows | OpticalAI",
    description: "Workflows for OpticalAI",
  });
  return (
    <div>
      <Headnav heading="Workflows | opticalAI" />
      <WorkflowsTable />
    </div>
  );
};

export default Workflows;
