import Text from "@/components/Text";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";

const Integrations = () => {
  return (
    <div className="my-[10px] px-5">
      <Accordion type="single" collapsible={true} defaultValue="item-1">
        <AccordionItem defaultChecked value="item-1">
          <AccordionTrigger className="text-gray-600">Webhooks</AccordionTrigger>
          <AccordionContent>
            <Text>We are in beta mode. contact support to get your webhook URL.</Text>
            <Button className="mt-[10px]">Contact support</Button>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Integrations;
