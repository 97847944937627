import * as Yup from "yup";
import { Button } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Eye, EyeOff } from "lucide-react";

import { Input } from "@/components/ui/input";

import useHead from "@/hooks/useHead";
import useTogglePwd from "@/hooks/useTogglePwd";

import useUpdatePassword from "./hooks/useUpdatePassword";

interface IFormInput {
  oldPwd: string;
  password: string;
}

const Password = () => {
  useHead({
    title: "Password | OpticalAI",
    description: "Password for OpticalAI",
  });

  const { showPwd, togglePwd } = useTogglePwd();
  const { showPwd: showConfirm, togglePwd: toggleConfirm } = useTogglePwd();

  const { loading, updatePassword } = useUpdatePassword();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      oldPwd: "",
      password: "",
    },
    mode: "onBlur",
    resolver: yupResolver(
      Yup.object().shape({
        oldPwd: Yup.string().required("Old password is required"),
        password: Yup.string().required("Password is required"),
      })
    ),
  });

  const onSubmit = async (data: IFormInput) => {
    const cb = await updatePassword(data);
    if (cb?.message) {
      setValue("oldPwd", "");
      setValue("password", "");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-xl font-semibold">Password Manager</h1>
      <p className="text-[14px] text-[#7c7c7c]">Set new password</p>
      <div className="flex justify-between text-[#7c7c7c] my-[20px] items-center gap-[20px]">
        <label
          htmlFor="email"
          className="block mb-2 text-[13px] font-medium text-gray-900 dark:text-white w-[500px]"
        >
          Your current password
          <p
            id="helper-text-explanation"
            className="mt-2 text-[12px] text-gray-500 dark:text-gray-400"
          >
            Enter you current password of your account.
          </p>
        </label>
        <div className="w-full">
          <Controller
            control={control}
            name="oldPwd"
            render={({ field }) => (
              <Input
                type={showPwd ? "text" : "password"}
                id="email"
                value={field.value}
                onChange={(e) => setValue("oldPwd", e.target.value)}
                name="oldPwd"
                aria-describedby="helper-text-explanation"
                placeholder="****"
                error={errors.oldPwd?.message}
                togglePassword={togglePwd}
                addornment={showPwd ? <Eye size={20} /> : <EyeOff size={20} />}
              />
            )}
          />
        </div>
      </div>
      <div className="flex justify-between text-[#7c7c7c] my-[20px] items-center gap-[20px]">
        <label
          htmlFor="email"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-[500px]"
        >
          Your new password
          <p
            id="helper-text-explanation"
            className="mt-2 text-sm text-gray-500 dark:text-gray-400"
          >
            New password that you want to set.
          </p>
        </label>
        <div className="w-full">
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <Input
                type={showConfirm ? "text" : "password"}
                id="email"
                value={field.value}
                onChange={(e) => setValue("password", e.target.value)}
                name="password"
                aria-describedby="helper-text-explanation"
                placeholder="****"
                error={errors.password?.message}
                togglePassword={toggleConfirm}
                addornment={
                  showConfirm ? <Eye size={20} /> : <EyeOff size={20} />
                }
              />
            )}
          />
        </div>
      </div>
      <div className="flex justify-start">
        <Button loading={loading} type="primary" htmlType="submit" className="w-[200px]">
          Save
        </Button>
      </div>
    </form>
  );
};

export default Password;
