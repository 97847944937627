import http from "@/http";
import { message } from "antd";
import { useState } from "react";

import { AxiosError } from "axios";

import { LoginResponse } from "@/model/user.model";

import { useNavigate, useLocation } from "react-router-dom";
import { useUserDispatch } from "@/providers/userProvider";
import { useTokenDispatch, TokenActions } from "@/providers/useAuthProvider";

import { appRoutes } from "@/utils/paths";

interface Response {
  url: string;
}

const useGoogle = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const tokenDispatch = useTokenDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const googleLink = async () => {
    try {
      setLoading(true);
      const response = await http.get<Response>("/auth/gcp/link");
      setLoading(false);
      return (window.location.href = response.data.url);
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof AxiosError) {
        message.error(error.response?.data.message);
      }
      throw error;
    }
  };

  const googleCallback = async (code: string) => {
    const decodedUser = atob(code);
    try {
      const parsedUser = JSON.parse(decodedUser) as LoginResponse;
      dispatch?.setUser(parsedUser.user);
      dispatch?.setAccountId(parsedUser.user.userId);
      tokenDispatch({
        type: TokenActions.setTokens,
        payload: {
          access_token: parsedUser.access_token,
          refresh_token: parsedUser.refresh_token,
        },
      });
      return navigate(state?.route || appRoutes.dashboard.WORKFLOWS.INDEX);
    } catch (error: unknown) {
      message.error((error as Error).message);
    }
  };

  return { googleLink, loading, googleCallback };
};

export default useGoogle;
