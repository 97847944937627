import { useQuery } from "@tanstack/react-query";
import React from "react";

import Editor from "./Editor";
import Stats from "./Stats";
import ThreadHeader from "./threads/ThreadHeader";
import Divider from "./threads/Divider";
import Message from "./threads/Message";
import {
  AttachementsSkeleton,
  // DividerSkeleton,
  HeaderSkeleton,
  MessageSkeleton,
} from "./Skelton";

import { useThread } from "@/providers/thredContext";
import { useReplay, Methods } from "@/providers/replayContext";

import { gmailApi } from "../api/gmail";
const Threads = () => {
  const { getThreadsMessages } = gmailApi();
  const { thread } = useThread();
  const { replayMessage } = useReplay();

  const [olderMessagesIndex, setOlderMessagesIndex] = React.useState(0);
  const [activeMessageIndex, setActiveMessageIndex] = React.useState(0);
  const [showAllMessages, setShowAllMessages] = React.useState(false);

  const { data, status, refetch } = useQuery({
    queryKey: ["threads", thread.thredId],
    queryFn: () => getThreadsMessages(thread.thredId),
    enabled: !!thread.thredId,
  });

  React.useEffect(() => {
    if (!data) return;
    setActiveMessageIndex(data.data.threads.messages.length - 1);
    setShowAllMessages(false);
  }, [thread.thredId, data]);

  React.useEffect(() => {
    const messageLength = data?.data.threads.messages.length ?? 0;
    setOlderMessagesIndex(messageLength - 2); // Start with all but the latest message hidden
    if (!data) return;
    setActiveMessageIndex(messageLength - 1); // Set the most recent message as active
  }, [data]);

  const handleShowOlderMessages = () => {
    setShowAllMessages(true); // Set all messages to visible
  };

  return (
    <div className="relative h-[calc(100vh-5px)] border-l w-full overflow-scroll">
      {!thread.thredId ? (
        <Stats />
      ) : (
        <div className="w-[calc(100vw-836px)] relative">
          <div className="px-[10px] py-[10px]">
            {status === "pending" ? (
              <HeaderSkeleton />
            ) : (
              <ThreadHeader
                subject={
                  data?.data.threads.messages[0]?.payload.headers.find(
                    (header) => header.name === "Subject"
                  )?.value as string
                }
                cc={
                  data?.data.threads.messages[0]?.payload.headers.find(
                    (header) => header.name === "Cc"
                  )?.value as string
                }
                from={
                  data?.data.threads.messages[0]?.payload.headers.find(
                    (header) => header.name === "From"
                  )?.value as string
                }
                threads={data?.data.threads.messages.length as number}
                to={
                  data?.data.threads.messages[0]?.payload.headers.find(
                    (header) => header.name === "To"
                  )?.value as string
                }
              />
            )}

            <div className="overflow-scroll h-auto">
              {status === "pending" ? (
                <MessageSkeleton />
              ) : (
                data?.data.threads.messages.map((message, index) => {
                  // Always show all messages but only activate them based on user interaction
                  const isLastMessage =
                    index === data?.data.threads.messages.length - 1;
                  const isActive = isLastMessage || showAllMessages;

                  return (
                    <div key={message?.id}>
                      <Message
                        active={isActive}
                        messageId={message?.id}
                        content={message?.snippet}
                        to={
                          message?.payload.headers.find(
                            (header) => header.name === "To"
                          )?.value
                        }
                        from={
                          message?.payload.headers.find(
                            (header) => header.name === "From"
                          )?.value as string
                        }
                        timeStamp={new Date(parseInt(message?.internalDate))}
                      />
                      {index === olderMessagesIndex &&
                        data?.data.threads.messages.length > 1 &&
                        !showAllMessages && (
                          <div className="mt-3">
                            <Divider
                              count={activeMessageIndex} // Showing count for older messages
                              onclick={handleShowOlderMessages}
                            />
                          </div>
                        )}
                    </div>
                  );
                })
              )}
              {status === "pending" ? <AttachementsSkeleton /> : null}
            </div>

            {replayMessage?.method != Methods.COMPOSE && (
              <div
                className={
                  "w-full sticky bottom-0  border-t bg-white shadow-2xl"
                }
              >
                <Editor refetch={refetch} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Threads;
