import opticalAi from '@/assets/opticalai.png'

const ai_engine = [
  {
    name: 'Optical AI Engine',
    description: 'OpticalAI excels in advanced data extraction and proactive fraud detection, streamlining invoice coding, ensuring effortless compliance, and integrating seamlessly with existing accounting systems',
    icon: opticalAi,
  }
]

import { Radio } from 'antd';

const Engine = () => {
  return (
    <div className='flex items-center justify-center'>
     <div className="max-w-[600px] bg-[#1677ff08] mt-[20px] rounded-md p-4 shadow-sm border">
      <h1 className='text-gray-700 text-[15px]'>Choose AI Engine</h1>
        <div className="flex py-[30px]">
          <div className=''>
          {ai_engine.map((engine, index) => (
            <div key={index} className="flex bg-white items-center rounded-md justify-center gap-4 border py-5 px-[10px] mb-[10px] shadow-sm hover:shadow-md">
              <Radio defaultChecked/>
              <img src={engine.icon} alt={engine.name} className="w-20 h-20" />
              <div className='w-full'>
                <h2 className='text-gray-700'>{engine.name}</h2>
                <p className='text-sm max-w-[400px] mt-2 text-gray-600'>{engine.description}</p>
              </div>
            </div>
          ))}
          <div className='border rounded-sm bg-[#f9f9f9]'>
            <p className='text-center text-[12px] py-2 text-gray-600'>We will add more Engine in future</p>
          </div>
          </div>
        </div>
     </div>
    </div>
  )
}

export default Engine