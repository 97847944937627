import React, { useState, useEffect, useRef } from "react";
import Text from "@/components/Text";
import { Badge } from "antd";
import { Calendar } from "lucide-react";

const PaymentsReminder: React.FC = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isSeeMore, setIsSeeMore] = useState(false);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollTop = scrollContainerRef.current.scrollTop;
      setIsSeeMore(scrollTop === 0);
    }
  };

  useEffect(() => {
    // check if the scroll container has a scroll
    if (scrollContainerRef.current) {
      const hasScroll =
        scrollContainerRef.current.scrollHeight >
        scrollContainerRef.current.clientHeight;
      setIsSeeMore(hasScroll);
    }
    scrollContainerRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainerRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleViewMore = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
      setIsSeeMore(false);
    }
  };

  return (
    <div className="mx-[20px] pt-[15px] border-t mt-[15px]">
      <div className="flex justify-between items-center">
        <Text bold>My upcoming payments</Text>
        <Calendar
          size={16}
          className="translate-x-0 text-gray-600 hover:text-gray-800 cursor-pointer hover:scale-110"
        />
      </div>
      <div
        ref={scrollContainerRef}
        className="mt-2 max-h-[240px] overflow-y-auto scrollbar-visible"
      >
        {Array.from({ length: 30 }).map((_, index) => (
          <div key={index} className="flex w-full my-3">
            <div className="bg-gray-100 border px-[10px] py-[5px] w-full rounded-md flex justify-between items-center hover:bg-gray-200/50 hover:shadow-md cursor-pointer border-green-400">
              <div className="flex flex-col gap-1">
                <Text big className="text-gray-700 line-through">
                  Netflix
                </Text>
                <Text className="text-sm text-gray-600">Today, 12:00 PM</Text>
              </div>
              <Badge status="success" size="default" />
            </div>
          </div>
        ))}
      </div>
      {isSeeMore && (
        <p
          className="text-gray-500 text-right text-sm cursor-pointer hover:underline"
          onClick={handleViewMore}
        >
          View more payments
        </p>
      )}
    </div>
  );
};

export default PaymentsReminder;
