import { Outlet, useNavigate, useLocation } from "react-router-dom";
import React from "react";

import Account from "@/views/Inbox/components/Account";
import Nav from "@/views/Inbox/components/Nav";
import Labels from "@/views/Inbox/components/Labels";
import PaymentsReminder from "@/views/Inbox/components/PaymentsReminder";

import { appRoutes } from "@/utils/paths";

const Inbox = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === appRoutes.dashboard.INBOX.ROOT) {
      navigate(appRoutes.dashboard.INBOX.ALL_MAILS);
    }
  }, [location.pathname, navigate]);

  return (
    <div className="flex fixed w-full">
      <div className="bg-[#fbfbfb] w-[300px] border-r rounded-tl-xl transition-all duration-300">
        <Account />
        <Nav />
        <Labels />
        <PaymentsReminder />
      </div>
      <div className="transition-all duration-300 w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default Inbox;
