import Headnav from "@/components/Navbars/Headnav";

import CardBox from "./components/Card";
import FunnelChart from "./charts/Funnel";
import { AreaChartUsage } from "./charts/Area";
import { DonutChartUsage } from "./charts/Dount";

import useHead from "@/hooks/useHead";

const Analytics = () => {
  useHead({
    title: "Dashboard | OpticalAI",
    description: "Dashboard for OpticalAI",
  });

  const data = [
    {
      title: "Total Documents",
      value: 12,
    },
    {
      title: "Workspace",
      value: 12,
    },
    {
      title: "Workflows",
      value: 1,
    },
    {
      title: "Users",
      value: 12,
    },
    {
      title: "Alerts",
      value: 0,
    },
  ];

  return (
    <div>
      <Headnav heading="Dashboard" />
      <div className="fixed h-full pb-[120px] m-5 w-[98%] overflow-scroll">
        <div className="flex gap-[20px] mb-[20px] flex-wrap">
          {data.map((key, index) => {
            return <CardBox title={key.title} value={key.value} key={index} />;
          })}
        </div>
        <FunnelChart />
        <div className="flex items-center py-[20px]">
          <AreaChartUsage />
          <DonutChartUsage />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
