/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation } from "react-router-dom";
import { ChevronRight, Plus, Trash, GitPullRequestCreate } from "lucide-react";
import { FaStarOfLife } from "react-icons/fa6";
import { useQuery, useMutation } from "@tanstack/react-query";
import { message, Spin, notification } from "antd";
import React from "react";

import { Button } from "@/components/ui/button";
import Text from "@/components/Text";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Switch } from "@/components/ui/switch";

import { useUser } from "@/providers/userProvider";

import useWorkflowSchema from "../hooks/useWorkflowSchema";

import { UpdateSchema } from "../components/UpdateSchema";
import AddNewField from "../components/AddNewField";
import DeleteField from "../components/DeleteField";

import { Datatype } from "@/types/schema";
import { adminAccess ,RoleEnum} from "@/types/role";
import AddSection from "../components/AddSection";

interface DeleteFieldProps {
  id: string;
  section: string;
  workflowId: string;
}

const EditSchema = () => {
  const renderSection = (
    title: string,
    data: Record<string, any>,
    section_name: string
  ) => {
    return (
      <div className="xl:w-2/2 mx-[20px] border rounded-md p-[5px] px-[20px] sm:w-full bg-[#1677ff08] mb-[20px]">
        <div className="flex justify-between">
          <p className="text-[15px] mt-[6px] text-gray-700 dark:text-gray-100">
        {title}
        <span className="text-sm"> ({ Object.keys(data)?.length})</span>
          </p>
          <Button
            onClick={() => {
              const isAllowed = adminAccess.includes(user?.role ?? RoleEnum.Admin);

              if (isAllowed) {
                handleAddField(section_name);
              } else {
                notification.open({
                  type: "error",
                  description:
                    "Contact to workflow manager to add more fields into " +
                    title,
                  message: "Permission Denied",
                });
              }
            }}
            variant={"link"}
            className="flex items-center"
          >
            <Plus size={20} />
            <span>Add new field</span>
          </Button>
        </div>
        <div className="flex gap-[10px] items-center">
          <Switch
            checked={
              Object.keys(data).length > 0 &&
              Object.keys(data).every((key) => data[key]?.is_visible)
            }
            onCheckedChange={() => {
              const isAllowed = adminAccess.includes(user?.role ?? RoleEnum.Admin);

              if (isAllowed) {
                handleAllToggleField(
                  section_name,
                  !Object.keys(data).every((key) => data[key]?.is_visible)
                );
              } else {
                notification.open({
                  type: "error",
                  description: (
                    <p>
                      <span className="font-bold capitalize">
                        {user?.role.concat(" ")}
                      </span>
                      role can't have right to toggle the all fields
                    </p>
                  ),
                  message: "Permission Denied",
                });
              }
            }}
            disabled={loadingForAllToggle}
          />
          <Text className="text-sm">Toggle all fields</Text>
        </div>
        <div className="!overflow-x-hidden max-h-[calc(100vh-200px)] mb-[5px]">
          {data &&
            Object.keys(data || {}).map((field, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-between py-[10px] pl-[10px] pr-[2px] my-[10px] border rounded-md hover:shadow-md group bg-white"
                >
                  <div>
                    <h4 className="text-[14px] text-gray-600">{data[field]?.label}</h4>
                    <h5 className="text-[12px] text-gray-500">
                      {data[field]?.description}
                    </h5>
                  </div>
                  <div className="flex items-center gap-[5px] justify-center">
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                          <Switch
                            checked={data[field]?.is_visible}
                            onClick={() => {
                              const isAllowed = adminAccess.includes(user?.role ?? RoleEnum.Admin);

                              if (isAllowed) {
                                handleToggleField(
                                  section_name,
                                  data[field]?.id
                                );
                              } else {
                                notification.open({
                                  type: "error",
                                  description: (
                                    <p>
                                      <span className="font-bold capitalize">
                                        {user?.role.concat(" ")}
                                      </span>
                                      role can't have right to toggle the fields
                                    </p>
                                  ),
                                  message: "Permission Denied",
                                });
                              }
                            }}
                            disabled={loadingForToggle}
                            className="group-hover:-translate-x-2 transition mr-2"
                          />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Toggle visibility</p>
                        </TooltipContent>
                      </Tooltip>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                          <FaStarOfLife
                            className="group-hover:-translate-x-3 transition"
                            strokeWidth={0.1}
                            fill={
                              data[field]?.is_required ? "#5889f3" : "#bfbfbf"
                            }
                            onClick={() => {
                              const isAllowed = adminAccess.includes(user?.role ?? RoleEnum.Admin);

                              if (isAllowed) {
                                handleToggleOption(section_name, data[field]);
                              } else {
                                notification.open({
                                  type: "error",
                                  description: (
                                    <p>
                                      <span className="font-bold capitalize">
                                        {user?.role.concat(" ")}
                                      </span>
                                      role can't have right to toggle the
                                      required parameter
                                    </p>
                                  ),
                                  message: "Permission Denied",
                                });
                              }
                            }}
                          />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Toggle Required</p>
                        </TooltipContent>
                      </Tooltip>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                          <ChevronRight
                            size={22}
                            strokeWidth={1.3}
                            className="text-gray-800 dark:text-[#fff] cursor-pointer group-hover:-translate-x-2 transition group-hover:bg-blue-700 rounded-md group-hover:text-white"
                            onClick={() => {
                              const isAllowed = adminAccess.includes(user?.role ?? RoleEnum.Admin);

                              if (isAllowed) {
                                handleEditField(section_name, data[field]);
                                setSchema(title);
                              } else {
                                notification.open({
                                  type: "error",
                                  description: (
                                    <p>
                                      <span className="font-bold capitalize">
                                        {user?.role.concat(" ")}
                                      </span>
                                      role don't have right to update the schema
                                      options
                                    </p>
                                  ),
                                  message: "Permission Denied",
                                });
                              }
                            }}
                          />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Edit schema</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                          <Trash
                            size={20}
                            strokeWidth={1.3}
                            className="text-[#464646] dark:text-[#fff] cursor-pointer hover:!text-red-500 translate-x-6 group-hover:-translate-x-1 transition"
                            onClick={() => {
                              const isAllowed = adminAccess.includes(user?.role ?? RoleEnum.Admin);

                              if (isAllowed) {
                                handleDeleteField(section_name, data[field].id);
                              } else {
                                notification.open({
                                  type: "error",
                                  description: (
                                    <p>
                                      <span className="font-bold capitalize">
                                        {user?.role.concat(" ")}
                                      </span>
                                      role don't have right to delete the field.
                                    </p>
                                  ),
                                  message: "Permission Denied",
                                });
                              }
                            }}
                          />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Delete field</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const location = useLocation();
  const { user } = useUser();

  const {
    getWorkflowSchema,
    toggleWorkflowField,
    toggleAllFields,
    updateWorkflowField,
  } = useWorkflowSchema();

  const [fieldDetails, setFieldDetails] = React.useState<Datatype>();
  const [showAddSection, setShowAddSection] = React.useState<boolean>(false);
  const [showUpdateSchema, setShowUpdateSchema] =
    React.useState<boolean>(false);
  const [showNewField, setShowNewField] = React.useState<boolean>(false);
  const [showDeleteField, setShowDeleteField] = React.useState<boolean>(false);
  const [sectionName, setSectionName] = React.useState("basic_information");
  const [deleteField, setDeleteField] = React.useState<DeleteFieldProps>(
    {} as DeleteFieldProps
  );
  const [schema, setSchema] = React.useState("");

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["getWorkflowSchema", location.pathname.split("/").pop()],
    queryFn: () => getWorkflowSchema(location.pathname.split("/")?.pop() || ""),
  });

  const { mutateAsync, data: updateWorkflowResponse } = useMutation({
    mutationKey: ["updateWorkflowField1"],
    mutationFn: updateWorkflowField,
    onSuccess: () => {
      refetch();
      setShowUpdateSchema(false);
    },
  });

  const {
    mutateAsync: toggleWorkflow,
    isPending: loadingForToggle,
    data: toggleWorkflowResponse,
  } = useMutation({
    mutationKey: ["deleteWorkflowField"],
    mutationFn: toggleWorkflowField,
    onSuccess: () => {
      refetch();
      setShowDeleteField(false);
    },
  });

  const {
    mutateAsync: toggleAllField,
    isPending: loadingForAllToggle,
    data: toggleAllFieldResponse,
  } = useMutation({
    mutationKey: ["toggleAllFields"],
    mutationFn: toggleAllFields,
    onSuccess: () => {
      refetch();
    },
  });

  const handleToggleField = async (section: string, id: string) => {
    message.open({
      type: "loading",
      content: "Toggling field...",
      key: "toggling",
    });
    toggleWorkflow({
      workflowId: location.pathname.split("/")?.pop() as string,
      section: section,
      id: id,
    });
    message.destroy("toggling");
    message.success(toggleWorkflowResponse?.data.message);
  };

  const handleToggleOption = (section: string, field: Record<string, any>) => {
    message.open({
      type: "loading",
      content: "Updating schema...",
      key: "updating",
    });
    mutateAsync({
      workflowId: location.pathname.split("/")?.pop() as string,
      section: section,
      id: field.id || "unknown_id",
      data: {
        label: field.label,
        description: field.description,
        id: field?.id || "id",
        is_visible: field?.is_visible as boolean,
        section: sectionName,
        data_type: field?.data_type,
        question: field?.question,
        date_format: field?.date_format,
      },
    }).then(() => {
      message.destroy("updating");
      message.success(updateWorkflowResponse?.data.message);
    });
  };

  const handleEditField = (section_name: string, field: Datatype) => {
    setFieldDetails(field);
    setShowUpdateSchema(true);
    setSectionName(section_name);
  };

  const handleAddField = (section_name: string) => {
    setShowNewField(true);
    setSectionName(section_name);
  };

  const handleDeleteField = (section: string, id: string) => {
    setDeleteField({
      id: id,
      section: section,
      workflowId: location.pathname.split("/").pop() || "",
    });
    setShowDeleteField(true);
  };

  const handleAllToggleField = (section_name: string, value: boolean) => {
    message.open({
      type: "loading",
      content: "Toggling all fields...",
      key: "togglingAll",
    });
    toggleAllField({
      workflowId: location.pathname.split("/")?.pop() as string,
      section_name,
      value,
    });
    message.destroy("togglingAll");
    message.success(toggleAllFieldResponse?.data.message);
  };

  return (
    <div className="px-[10px] w-full py-[20px]">
      <UpdateSchema
        refetch={refetch}
        schemaInfo={fieldDetails as Datatype}
        showUpdateSchema={showUpdateSchema}
        setShowUpdateSchema={setShowUpdateSchema}
        schema={schema}
        sectionName={sectionName}
        key={fieldDetails?.id}
      />
      <AddNewField
        showNewField={showNewField}
        setShowNewField={setShowNewField}
        section_name={sectionName}
        refetch={refetch}
        key={sectionName}
      />
      <DeleteField
        closeDialog={showDeleteField}
        setCloseDialog={setShowDeleteField}
        data={deleteField}
        refetch={refetch}
      />
      <AddSection
        refetch={refetch}
        setCloseDialog={setShowAddSection}
        closeDialog={showAddSection}
        workflowId={location.pathname.split("/").pop() || ""}
      />
      {isLoading ? (
        <div className="w-full text-center mt-[50px]">
          <Spin size="large" />
        </div>
      ) : data && data.data?.sections ? (
        <div className="flex flex-col items-center justify-center">
          {Object.entries(data.data?.sections).map(
            ([sectionName, sectionData]) =>
              renderSection(
                sectionName
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" "),
                sectionData,
                sectionName
              )
          )}
          <div className="xl:w-2/2 mx-[20px] border rounded-md p-[5px] px-[20px] sm:w-full bg-[#1677ff08] flex items-center justify-center py-[20px]">
            <Button
              variant={"outline"}
              size={"lg"}
              className="hover:bg-blue-500 text-gray-600 hover:text-white flex items-center justify-center gap-[5px] group"
              onClick={() => {
                const isAllowed = adminAccess.includes(user?.role ?? RoleEnum.Admin);
  
                if (isAllowed) {
                  setShowAddSection(true);
                } else {
                  notification.open({
                    type: "error",
                    description: (
                      <p>
                        <span className="font-bold capitalize">
                          {user?.role.concat(" ")}
                        </span>
                        role can't have right to create new sections
                      </p>
                    ),
                    message: "Permission Denied",
                  });
                }
              }}
            >
              <GitPullRequestCreate
                size={20}
                className="text-gray-600 group-hover:text-white"
                strokeWidth={1.2}
              />
              New sections
            </Button>
          </div>
        </div>
      ) : (
        <div className="xl:w-2/2 mx-[20px] border rounded-md p-[5px] px-[20px] sm:w-full bg-[#1677ff08] flex items-center justify-center py-[20px]">
          <Button
            variant={"outline"}
            size={"lg"}
            className="hover:bg-blue-500 text-gray-600 hover:text-white flex items-center justify-center gap-[5px] group"
            onClick={() => {
              const isAllowed = adminAccess.includes(user?.role ?? RoleEnum.Admin);

              if (isAllowed) {
                setShowAddSection(true);
              } else {
                notification.open({
                  type: "error",
                  description: (
                    <p>
                      <span className="font-bold capitalize">
                        {user?.role.concat(" ")}
                      </span>
                      role can't have right to create new sections
                    </p>
                  ),
                  message: "Permission Denied",
                });
              }
            }}
          >
            <GitPullRequestCreate
              size={20}
              className="text-gray-600 group-hover:text-white"
              strokeWidth={1.2}
            />
            New sections
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditSchema;
