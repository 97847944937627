import Text from "@/components/Text";

import {MoreVertical} from 'lucide-react'

const labels = [
  {
    label: "Work",
    color: "border-blue-500",
  },
  {
    label: "Team events",
    color: "border-purple-500",
  },
  {
    label: "Application",
    color: "border-cyan-500",
  },
  {
    label: "Promotions",
    color: "border-red-500",
  },
  {
    label: "Social",
    color: "border-yellow-500",
  }
];

const Labels = () => {
  return (
    <div className="mx-[20px] pt-[15px] border-t">
     <div className="flex justify-between items-center">
     <Text bold>Labels</Text>
     <MoreVertical size={16} className="translate-x-1 hover:text-gray-800 cursor-pointer hover:scale-110"/>
     </div>
      <div className="mt-1">
        {labels.map((label, index) => (
          <div key={index} className="flex items-center justify-between mt-5">
            <div className="flex items-center">
              <div
                className={`h-[15px] w-[15px] rounded-md border-2 ${label.color}`}
              ></div>
              <Text className="ml-2 text-sm">{label.label}</Text>
            </div>
            <span className="text-sm text-gray-600">{9 + index}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Labels;
