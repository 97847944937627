import React from "react";
import http from "@/http";
import { AxiosError } from "axios";
import {message} from "antd";
import { Skeleton } from "@/components/ui/skeleton";
import { useQuery, useMutation } from "@tanstack/react-query";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/dialog";
import { Checkbox } from "./ui/checkbox";
import { Button } from "./ui/button";

import { useUser } from "@/providers/userProvider";

import { ReleaseNoteType } from "@/types/relaseNote";

const ReleaseNote = () => {
  const { user } = useUser();

  const abort = new AbortController();

  const { data, isLoading } = useQuery({
    queryKey: ["fetch-relaseNote", abort],
    queryFn: () => {
      return http.get<ReleaseNoteType>("/releasenote/get-release-notes", {
        signal: abort.signal,
      });
    },
  });

  const { isPaused, mutate } = useMutation({
    mutationKey: ["seen-releasenote"],
    mutationFn: () => {
      return http.put<{ message: string }>("/releasenote/seen");
    },
    onError: (error: AxiosError) => {
      message.error((error?.response?.data as { message: string }).message);
    },
    onSuccess: (data) => {
      message.success(data?.data.message);
    },
  });

  React.useEffect(() => {
    if (user?.is_release_note_seen) {
      abort.abort();
    }
  }, [
    user?.is_release_note_seen,
    abort,
  ]);

  const [show, setShow] = React.useState(!user?.is_release_note_seen);
  const [checked, setChecked] = React.useState(false);

  const onClose = () => {
    if (checked) {
      mutate();
      setShow(false);
    }
    setShow(false);
  };

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent className="max-h-[600px] max-w-[600px] focus-within:border-blue-900">
        <DialogHeader>
          <DialogTitle>
            {!isLoading ? (
              <>
                Release note published on{" "}
                {data?.data?.release_date &&
                  new Date(data?.data.release_date).toLocaleDateString(
                    "us-en",
                    {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      calendar: "gregory",
                      numberingSystem: "latn",
                      localeMatcher: "lookup",
                      weekday: "short",
                    }
                  )}
                <p className="text-sm font-thin !font-mono mt-1 dark:text-gray-400 text-gray-700">
                  version {data?.data?.version}
                </p>
              </>
            ) : (
              <div className="flex flex-col gap-[10px]">
                <Skeleton className="w-[400px] h-[10px] dark:bg-gray-600" />
                <Skeleton className="w-[170px] h-[10px] dark:bg-gray-600" />
              </div>
            )}
          </DialogTitle>
          {!isLoading ? (
            <>
              {data?.data && data.data.features?.length > 0 && (
                <DialogDescription>
                  Here are the latest {data?.data.features?.length} features.
                </DialogDescription>
              )}
            </>
          ) : (
            <div>
              <Skeleton className="w-[210px] h-[10px] mt-[10px] dark:bg-gray-600" />
            </div>
          )}
        </DialogHeader>
        {!isLoading ? (
          <ol className="max-h-[400px] overflow-scroll mt-[-10px]">
            {data?.data?.features?.map((feature, index: number) => (
              <li
                key={feature.title + index}
                className="list-decimal ml-4 mt-1"
              >
                <h3 className="text-[16px]">{feature.title}</h3>
                <p className="text-sm text-gray-500">{feature.description}</p>
              </li>
            ))}
          </ol>
        ) : (
          <div className="flex flex-col gap-3">
            <Skeleton className="h-[60px] w-full dark:bg-gray-600" />
            <Skeleton className="h-[60px] w-full dark:bg-gray-600" />
            <Skeleton className="h-[60px] w-full dark:bg-gray-600" />
          </div>
        )}
        {!isLoading &&
          data?.data &&
          (data?.data?.bugFixes?.length ?? 0) > 0 && (
            <>
              <DialogDescription className="text-green-500">
                Here are the latest {data?.data.bugFixes?.length} bugs fixed.
              </DialogDescription>
              <ol className="max-h-[400px] overflow-scroll">
                {data?.data?.bugFixes?.map((bug, index: number) => (
                  <li
                    key={bug.title + index}
                    className="list-decimal ml-4 mt-1"
                  >
                    <h3 className="text-[16px]">{bug.title}</h3>
                    <p className="text-sm text-gray-500">{bug.description}</p>
                  </li>
                ))}
              </ol>
            </>
          )}
        {!isLoading ? (
          <div className="flex items-center gap-2">
            <Checkbox
              checked={checked}
              onCheckedChange={() => setChecked(!checked)}
            />
            <p className="text-sm text-gray-400">Don't show this again</p>
          </div>
        ) : (
          <Skeleton className="h-[10px] w-[200px] dark:bg-gray-600" />
        )}
        <DialogFooter>
          {!isLoading ? (
            <Button onClick={onClose} loading={isPaused}>
              Close
            </Button>
          ) : (
            <Skeleton className="h-[40px] w-[100px] dark:bg-gray-600" />
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ReleaseNote;
