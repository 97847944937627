import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routers/index";
import "./index.css";
import "intro.js/introjs.css";

import { AuthProvider as RefreshAuthPrompt } from "./providers/authContext";
import UserProvider from "./providers/userProvider";
import AuthProvider from "./providers/useAuthProvider";
import { DrawerProvider } from "./providers/DrawerContext";
import { GeometryProvider } from "./providers/geometryProvider";
import { FileProvider } from "./providers/useFileProvider";
import { ThemeProvider } from "./providers/useThemeProvider";
import { ScaleProvider } from "./providers/ScaleProvider";
import { MyMailProvider } from "./providers/myMailContext";
import { ThreadProvider } from "./providers/thredContext";
import { ReplayProvider } from "./providers/replayContext";

import { ASCII_ART } from "./constant";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

console.info(ASCII_ART);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 5,
      retryDelay: 10000,
      networkMode: "online",
    },
  },
});

Sentry.init({
  dsn: "https://71ad418fbc2066349ed55ad10cf8f062@o4507713485602816.ingest.us.sentry.io/4507713488879616",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/app.getopticalai\.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <ThemeProvider defaultTheme="light">
        <AuthProvider>
          <ReplayProvider>
            <MyMailProvider>
              <ThreadProvider>
                <GeometryProvider>
                  <DrawerProvider>
                    <ScaleProvider>
                      <UserProvider>
                        <FileProvider>
                          <RefreshAuthPrompt>
                            <BrowserRouter>
                              <Routes />
                            </BrowserRouter>
                          </RefreshAuthPrompt>
                        </FileProvider>
                      </UserProvider>
                    </ScaleProvider>
                  </DrawerProvider>
                </GeometryProvider>
              </ThreadProvider>
            </MyMailProvider>
          </ReplayProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
