import http from "@/http";

import { Message, Threads, Attachment } from "@/types/email.type";

export interface Page {
  messages: {
    messages: Message[];
    nextPageToken: string | null;
  };
  lastSyncDate: Date;
}

interface GmailResponse {
  mail: {
    content: string;
    attachments: Attachment[];
  };
}

interface SendPayload {
  to: string;
  subject: string;
  body: string;
  attachments: {
    filename: string;
    mimeType: string;
    content: string;
  }[];
}

interface count{
  messageCount: number;
  siceDate: string
}

interface CountResponse {
  INBOX:count,
  SENT:count,
  SPAM:count,
  TRASH:count,
}

export const gmailApi = () => {
  const connectGmail = async () => {
    return await http.get<{ url: string }>("/inbox/activate-gmail");
  };
  const getMessageList = async (
    nextPageToken: string | null,
    pageQuery?: string
  ) => {
    return await http.get<Page>(
      `/inbox/get-mail-list?pageToken=${nextPageToken}&pageQuery=${pageQuery}`);
  };
  const syncMessageList = async () => {
    return await http.get<Page>("/inbox/sync-mail");
  };

  const getMailContent = async (id: string) => {
    return await http.get<GmailResponse>(`/inbox/get-mail-content/${id}`);
  };

  const getThreadsMessages = async (id: string | null) => {
    return await http.get<Threads>(`/inbox/get-threads/${id}`);
  };

  const replayMail = async (message_id: string, body: string) => {
    return await http.post<{ message: string }>(
      `/inbox/replay-mail/${message_id}`,
      {
        message_body: body,
      }
    );
  };

  const sendMail = async (payload: SendPayload) => {
    return await http.post<{ id: string }>("/inbox/send-mail", payload);
  };

  const getLablesCount = async () => {
    return await http.get<CountResponse>("/inbox/get-labels-count");
  }

  return {
    connectGmail,
    getMessageList,
    getMailContent,
    syncMessageList,
    getThreadsMessages,
    replayMail,
    sendMail,
    getLablesCount
  };
};
