import React from "react";
import { Input, Button, Form, message } from "antd";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Plus } from "lucide-react";
import { DndContext, closestCenter, DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";

import Stage from "../components/Stage";

import { StageTypes, useApprover } from "../hooks/useApprover";
import { AxiosError } from "axios";

const Approvals: React.FC = () => {
  const [stages, setStages] = React.useState<StageTypes[]>([]);
  const [ruleInfo, setRuleInfo] = React.useState<{
    rule_name: string;
    description: string;
  }>();
  const [basicForm] = Form.useForm();
  const { createApprovalRule, getApprovalRule } = useApprover();
  const { pathname } = useLocation();

  const addStage = () => {
    setStages([
      ...stages,
      {
        id: stages.length + 1,
        name: `Stage ${stages.length + 1}`,
        reviewer: "",
        open: false,
        isEditing: false,
        openReviewer: false,
        openFlagCondition: false,
        appliedTo: "",
        flagCondition: 0,
        condition: "",
      },
    ]);
  };

  const toggleStage = (id: number) => {
    setStages(
      stages.map((stage) =>
        stage.id === id ? { ...stage, open: !stage.open } : stage
      )
    );
  };

  const editStageName = (id: number, name: string) => {
    setStages(
      stages.map((stage) =>
        stage.id === id ? { ...stage, name: name } : stage
      )
    );
  };

  const enableEditing = (id: number) => {
    const currentStage = stages.find((stage) => stage.id === id);
    if (currentStage?.name === "") {
      return message.error("Stage name cannot be empty");
    }

    const duplicateName = stages.some(
      (stage) => stage.name === currentStage?.name && stage.id !== id
    );

    if (duplicateName) {
      return message.error(
        `Stage name "${currentStage?.name}" is duplicated. Please ensure all stage names are unique.`
      );
    }
    setStages(
      stages.map((stage) =>
        stage.id === id ? { ...stage, isEditing: !stage.isEditing } : stage
      )
    );
  };

  const onSelectFlagCondition = (id: number, flagCondition: number) => {
    setStages(
      stages.map((stage) =>
        stage.id === id ? { ...stage, flagCondition: flagCondition } : stage
      )
    );
  };

  const onSelectReviewer = (id: number, reviewer: string) => {
    setStages(
      stages.map((stage) =>
        stage.id === id
          ? {
              ...stage,
              reviewer: reviewer,
              openReviewer: !stage.openReviewer,
            }
          : stage
      )
    );
  };

  const onOpenAppliedToModal = (id: number) => {
    setStages(
      stages.map((stage) =>
        stage.id === id ? { ...stage, openFlagCondition: true } : stage
      )
    );
  };

  const onOpenReviewerModal = (id: number) => {
    setStages(
      stages.map((stage) =>
        stage.id === id ? { ...stage, openReviewer: true } : stage
      )
    );
  };

  const onSelectAppliedTo = (id: number, appliedTo: string) => {
    setStages(
      stages.map((stage) =>
        stage.id === id
          ? {
              ...stage,
              appliedTo: appliedTo,
              openFlagCondition: !stage.openFlagCondition,
            }
          : stage
      )
    );
  };

  const onSelectCondition = (id: number, condition: string) => {
    setStages(
      stages.map((stage) =>
        stage.id === id ? { ...stage, condition: condition } : stage
      )
    );
  };

  const removeStage = (id: number) => {
    setStages(stages.filter((stage) => stage.id !== id));
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over) {
      if (active.id !== over.id) {
        setStages((stages) => {
          const oldIndex = stages.findIndex((stage) => stage.id === active.id);
          const newIndex = stages.findIndex((stage) => stage.id === over.id);
          return arrayMove(stages, oldIndex, newIndex);
        });
      }
    }
  };

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["create-approval"],
    mutationFn: createApprovalRule,
    onSuccess: (data) => {
      setStages(data.data.rule.stages);
      basicForm.setFieldsValue({
        rule_name: data.data.rule.info.rule_name as string,
        description: data.data.rule.info.description as string,
      });
      message.success(
        (data && data.data.message) || "Approval rule created successfully"
      );
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
    },
  });

  const { data } = useQuery({
    queryKey: ["get-approval-rule", pathname.split("/").pop()],
    queryFn: () => getApprovalRule(pathname.split("/").pop() as string),
  });

  React.useEffect(() => {
    if (data) {
      setRuleInfo({
        rule_name: data.data.info.rule_name as string,
        description: data.data.info.description as string,
      } as { rule_name: string; description: string });
      setStages(data.data.stages);
      basicForm.setFieldsValue({
        rule_name: data.data.info.rule_name as string,
        description: data.data.info.description as string,
      });
    }
  }, [data]);

  const onFinish = (value: { rule_name: string; description: string }) => {
    if (stages.length === 0) {
      return message.error("Please add at least one stage");
    }
    const missingFields = stages
      .filter(
        (stage) =>
          stage.reviewer === "" ||
          stage.appliedTo === "" ||
          stage.condition === ""
      )
      .map((stage) => {
        const missing = [];
        if (stage.reviewer === "") missing.push("reviewer");
        if (stage.appliedTo === "") missing.push("applied to");
        if (stage.condition === "") missing.push("condition");
        return { name: stage.name, missing };
      });

    if (missingFields.length > 0) {
      const errorMessages = missingFields.map(
        (stage) =>
          `Please select ${stage.missing.join(", ")} for the stage "${
            stage.name
          }"`
      );
      return message.error(errorMessages.join("\n"));
    }
    mutateAsync({
      data: {
        info: value,
        workflow_id: pathname.split("/").pop() as string,
        stages: stages.map((stage) => ({
          ...stage,
          open: false,
          isEditing: false,
          openReviewer: false,
          openFlagCondition: false,
        })),
      },
    });
  };

  return (
    <div className="bg-[#1677ff08] max-w-[600px] mx-auto px-[20px] py-[10px] my-[20px] rounded-md shadow-sm border">
      <div className="max-w-[500px]">
        <h1 className="text-gray-700">Add approval rule</h1>
      </div>
      <Form
        form={basicForm}
        onFinish={onFinish}
        name="baisc"
        layout="vertical"
        autoComplete="off"
        className="mt-5"
      >
        <Form.Item
          hasFeedback
          validateFirst
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Approval rule name is required" },
            { min: 6, message: "Continue input to exceed 6 chars" },
          ]}
          validateDebounce={1000}
          name="rule_name"
          className=" mt-2"
        >
          <label className="text-gray-600 text-sm">Approval rule name</label>
          <Input
            {...basicForm}
            onChange={(e) => {
              setRuleInfo((prev) => {
                if (prev) {
                  return { ...prev, rule_name: e.target.value };
                } else {
                  return { rule_name: e.target.value, description: "" };
                }
              });
              basicForm.setFieldsValue({ rule_name: e.target.value });
            }}
            value={ruleInfo?.rule_name}
            name="rule_name"
            placeholder="Enter the name of the approval rule"
            className="mt-1"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          validateFirst
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Description is required" },
            { min: 6, message: "Continue input to exceed 6 chars" },
          ]}
          validateDebounce={1000}
          name="description"
          className=" mt-2"
        >
          <div className=" mt-2">
            <label className="text-gray-600 text-sm">Description</label>
            <Input.TextArea
              placeholder="Enter the description of the approval rule"
              className="max-h-[100px] mt-1"
              value={ruleInfo?.description}
              {...basicForm}
              onChange={(e) => {
                setRuleInfo((prev) => {
                  if (prev) {
                    return { ...prev, description: e.target.value };
                  } else {
                    return { rule_name: "", description: e.target.value };
                  }
                });
                basicForm.setFieldsValue({ description: e.target.value });
              }}
              name="description"
            />
          </div>
        </Form.Item>
        <div className="py-3">
          <h2 className="uppercase text-sm font-semibold text-gray-600">
            Add reviewers
          </h2>
          <p className="text-[11px] text-gray-600">
            Send you invoices for approvals to selected team members in the
            order you define.
          </p>
          <div className="my-2 flex flex-col gap-[10px]">
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
              <SortableContext
                items={stages}
                strategy={verticalListSortingStrategy}
              >
                {stages.map((stage) => (
                  <Stage
                    key={stage.id}
                    stage={stage}
                    onToggle={toggleStage}
                    onEditName={editStageName}
                    enableEditing={enableEditing}
                    removeStage={removeStage}
                    onSelectReviewer={onSelectReviewer}
                    onOpenReviewer={onOpenReviewerModal}
                    onSelectAppliedTo={onSelectAppliedTo}
                    onSelectFlagCondition={onSelectFlagCondition}
                    onOpenAppliedToModal={onOpenAppliedToModal}
                    onSelectCondition={onSelectCondition}
                  />
                ))}
              </SortableContext>
            </DndContext>
            <Button
              type="text"
              size="small"
              className="w-[100px] p-0 m-0 font-popins my-2 text-sm gap-[2px] text-blue-600"
              onClick={addStage}
            >
              <Plus size={18} strokeWidth={1.5} />
              add stage
            </Button>
          </div>
        </div>
        <div className="flex gap-[10px] items-center justify-end">
          <Button loading={isPending} type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Approvals;
