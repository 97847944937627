import useHead from "@/hooks/useHead";
import Headnav from "@/components/Navbars/Headnav";
import { ConfigWorkflowsTable } from "@/components/table/configWorkflowsTable";

const AllWorkflows = () => {
  useHead({
    title: "Configuration | OpticalAI",
    description: "Configuration for OpticalAI",
  });
  return (
    <div>
      <Headnav heading="Configuration" />
      <ConfigWorkflowsTable />
    </div>
  );
};

export default AllWorkflows;
