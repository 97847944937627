import IconCloud from "@/components/magicui/icon-cloud";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import { Copy } from "lucide-react";

const Inbounds = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex items-center justify-center mt-[100px]">
      <div className="relative flex h-[calc(100vh-400px)] w-full max-w-[500px] items-center justify-center px-20">
        <IconCloud
          key={1}
          iconSlugs={[
            "gmail",
            "google",
            "icloud",
            "zoho",
            "zoom",
            "mailchimp",
            "minutemailer",
            "maildotru",
            "protonmail",
          ]}
        />
      </div>
      <div className="pr-[20px] max-w-[620px]">
        <div className="max-w-[540px]">
          <p className="text-gray-700 leading-[20px] text-[12px]">
            <span className="text-[16px]">
              Streamline your process by sending invoices directly to this email
            </span>
            <br /> <p className="mt-2"><span className="text-green-700">Note</span> : Invoices
            received here will be exclusively visible in the current workflow
            for enhanced focus.{" "}
            <span className="text-blue-700 underline cursor-pointer">
              Contact us
            </span>{" "}
            for advanced email integrations.</p>
          </p>
        </div>
        <div className="flex items-center mt-10">
          <p className="bg-blue-50 text-gray-500 px-[10px] py-[10px] text-[15px] rounded-md border border-blue-300">
            {pathname.split("/").pop()?.concat("@docbots.ai")}
          </p>
          <Copy
            className="ml-[10px] cursor-pointer active:scale-110 hover:text-blue-600"
            onClick={() => {
              navigator.clipboard.writeText(
                pathname.split("/").pop()?.concat("@docbots.ai") as string
              );
              message.success("mail copy to clipboard");
            }}
            size={22}
          />
        </div>
      </div>
    </div>
  );
};

export default Inbounds;
