import { useEffect, useState, useRef } from "react";
import DOMPurify from "dompurify";


// Helper function to ensure all links open in new tabs
const modifyLinksToOpenInNewTab = (htmlContent: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  const links = doc.querySelectorAll("a");

  links.forEach((link) => {
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
  });

  return doc.documentElement.innerHTML;
};

const EmailViewer = ({ content }: { content: string }) => {
  const [emailContent, setEmailContent] = useState<string | null>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (content) {
      const sanitizedContent = DOMPurify.sanitize(content || "");
      const modifiedContent = modifyLinksToOpenInNewTab(sanitizedContent);
        // Inject the font style into the content
        const styledContent = `
        <style>
          body { font-family: sans-serif; font-size: 13px; }
        </style>
        ${modifiedContent}
      `;
      setEmailContent(styledContent);
    }
  }, [content]);

  useEffect(() => {
    const adjustIframeHeight = () => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow && iframe.contentDocument) {
        // Adjust iframe height based on its content
        iframe.style.height = "auto"; // Reset the height first to avoid locking
        const contentHeight = iframe.contentDocument.body.scrollHeight+20;
        iframe.style.height = `${Math.min(contentHeight, 600)}px`; // Ensure max height of 500px
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = () => adjustIframeHeight();
    }
  }, [emailContent]);

  if (!emailContent) {
    return <p>Loading...</p>;
  }

  return (
    <iframe
      ref={iframeRef}
      title="Email Content"
      sandbox="allow-same-origin allow-scripts allow-popups"
      className="w-full"
      style={{
        maxHeight: "600px",
        width: "100%",
        border: "none",
        overflowY: "auto",
        fontFamily:'sans-serif'
      }}
      srcDoc={emailContent}
    />
  );
};

export default EmailViewer;
