import React from "react";
import { AArrowDown, AtSign, PhoneCall, Building, ExternalLink, Shield } from "lucide-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button } from "antd";

import { Input } from "@/components/ui/input";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

import { Image } from "lucide-react";

import useHead from "@/hooks/useHead";

import { useUser, useUserDispatch } from "@/providers/userProvider";

import useUpdateProfile from "./hooks/useUpdateProfile";
import useUpdateProfileImage from "./hooks/useUpdateProfileImage";

interface IFormInput {
  name: string;
  phone: string;
}

const Profile = () => {
  useHead({
    title: "Profile | OpticalAI",
    description: "Profile for OpticalAI",
  });

  const profileRef = React.useRef<HTMLInputElement>(null);

  const { user } = useUser();
  const dispatch = useUserDispatch()?.setUser;
  const { loading, updateProfile } = useUpdateProfile();
  const {
    updateProfileImage,
    loading: uploadLoading,
    data,
  } = useUpdateProfileImage();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      name: user?.name,
      phone: user?.phone ? user?.phone : "",
    },
    mode: "onBlur",
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phone: Yup.string()
          .required("Phone number is required")
          .max(10, "invalid phone number. Should be of 10 digit")
          .min(10, "invalid phone number. Should be of 10 digit"),
      })
    ),
  });

  const openImageFinder = () => {
    profileRef.current?.click();
  };

  const changeProfileImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (file) {
      updateProfileImage(file);
    }
  };

  React.useEffect(() => {
    if (data?.user) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(data && data.user);
    }
  }, [data]);

  return (
    <form onSubmit={handleSubmit((data: IFormInput) => updateProfile(data))}>
      <h1 className="text-xl font-semibold">Profile Manager</h1>
      <p className="text-[14px] text-[#7c7c7c]">
        Update your profile and personal details.
      </p>
      <div className="flex mx-auto justify-between items-center mt-[20px]">
        <div className="flex items-center justify-center gap-[20px] group relative">
          <input
            type="file"
            accept="image/*"
            ref={profileRef}
            onChange={changeProfileImage}
            className="hidden"
          />
          {!user?.profile_image ? (
            <Image className="w-[150px] h-[150px]" strokeWidth={0.3} />
          ) : (
            <Avatar className="w-[150px] h-[150px] !rounded-[5px]">
              <AvatarImage
                src={user?.profile_image}
                loading="eager"
                className="object-cover"
                alt={user?.name}
              />
              <AvatarFallback className="!rounded-[5px] text-2xl">
                {user &&
                  user?.name?.charAt(0) + user?.name?.split(" ")[1]?.charAt(0)}
              </AvatarFallback>
            </Avatar>
          )}
          <div className="absolute invisible group-hover:visible">
            <Button
              className="flex gap-[5px]"
              loading={uploadLoading}
              htmlType="button"
              onClick={openImageFinder}
              type="dashed"
            >
              <Image size={20} strokeWidth={1.2} />
              Update profile
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-[20px] py-[10px] rounded-2xl w-[400px]">
        <div className="grid grid-cols-2 gap-[20px] w-[700px]">
          <div className="flex w-full flex-col justify-between text-[#7c7c7c] my-[20px]">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Account role
            </label>
            <Input
              type="organization_name"
              id="organization_name"
              aria-describedby="helper-text-explanation"
              value={user?.role}
              className="cursor-not-allowed capitalize "
              placeholder={user?.role}
              addornment={<Shield size={20} strokeWidth={1.2} />}
            />
          </div>
          <div className="flex w-full flex-col justify-between text-[#7c7c7c] my-[20px]">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Organization name
            </label>
            <Input
              type="organization_name"
              id="organization_name"
              aria-describedby="helper-text-explanation"
              value={user?.organization_name || "Not available"}
              className="cursor-not-allowed"
              placeholder={user?.organization_name || "Not available"}
              addornment={<Building size={20} strokeWidth={1.2} />}
            />
          </div>
        </div>
        <div className="flex flex-col justify-between text-[#7c7c7c] w-[700px] mb-[20px]">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 w-[500px] dark:text-white w-[500px]"
          >
            Organization address
          </label>
          <textarea
            id="organization_name"
            aria-describedby="helper-text-explanation"
            value={user?.website || "Not available"}
            className="cursor-not-allowed border rounded-md p-1 min-h-[45px] max-h-[80px]"
            placeholder={user?.website || "Not available"}
          />
        </div>
        <div className="flex flex-col justify-between text-[#7c7c7c] w-[700px]">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 w-[500px] dark:text-white w-[500px]"
          >
            Organization website
          </label>
          <Input
            type="organization_name"
            id="organization_name"
            aria-describedby="helper-text-explanation"
            value={user?.website || "Not available"}
            className="cursor-not-allowed"
            placeholder={user?.website || "Not available"}
            addornment={<ExternalLink size={20} strokeWidth={1.2} onClick={()=>{
              window.open(user?.website || 'https://getopticalai.com', "_blank")
            }}/>}
          />
        </div>
        <div className="flex flex-col justify-between text-[#7c7c7c] w-[700px] my-[20px]">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-[500px]"
          >
            Full name
          </label>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                type="text"
                name="name"
                id="name"
                value={field.value}
                placeholder={"Your full name"}
                aria-describedby="helper-text-explanation"
                onChange={({ target }) => setValue("name", target.value)}
                addornment={<AArrowDown size={20} strokeWidth={1.2} />}
                error={errors.name?.message}
              />
            )}
          />
        </div>
        <div className="flex flex-col justify-between text-[#7c7c7c] w-[700px] my-[20px]">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 w-[500px] dark:text-white w-[500px]"
          >
            Email
          </label>
          <Input
            type="email"
            id="email"
            className="cursor-not-allowed"
            aria-describedby="helper-text-explanation"
            value={user?.email}
            placeholder={user?.email}
            addornment={<AtSign size={20} strokeWidth={1.2} />}
          />
        </div>
        <div className="flex flex-col justify-between text-[#7c7c7c] w-[700px] my-[20px]">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-[500px]"
          >
            Contact number
          </label>
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <Input
                name="phone"
                type="number"
                id="phone"
                aria-describedby="helper-text-explanation"
                value={field.value}
                error={errors.phone?.message}
                placeholder={"Your contact number"}
                onChange={({ target }) => setValue("phone", target.value)}
                addornment={<PhoneCall size={20} strokeWidth={1.2} />}
              />
            )}
          />
        </div>
      </div>
      <div className="flex items-center gap-[10px] justify-start">
        <Button
          loading={loading}
          type="primary"
          className="w-[200px]"
          htmlType="submit"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default Profile;
