import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, message, Steps, theme } from "antd";

import Headnav from "@/components/Navbars/Headnav";

import Schema from "./workflows/Steps/Schema";
import Integrations from "./workflows/Steps/Integrations";
import Inbounds from "./workflows/Steps/Inbounds";
import GL_Codes from "./workflows/Steps/GL_codes";
import Engine from "./workflows/Steps/Engine";
import Approvals from "./workflows/Steps/Approvals";

import useHead from "@/hooks/useHead";

const steps = [
  {
    title: "Inbounds",
    content: <Inbounds />,
  },
  {
    title: "Schema",
    content: <Schema />,
  },
  {
    title: "AI Engine",
    content: <Engine />,
  },
  {
    title: "GL Coding Rules",
    content: <GL_Codes />,
  },
  {
    title: "Approvals",
    content: <Approvals />,
  },
  {
    title: "Export",
    content: <Integrations />,
  },
];

const WorkflowConfig = () => {

  const { hash, state, pathname } = useLocation();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const [workflowName, setWorkflowName] = React.useState<string | undefined>(
    ""
  );


  React.useEffect(() => {
    if (state?.workflow_name !== undefined) {
      setWorkflowName(state?.workflow_name);
    }
  }, [state]);

  React.useEffect(() => {
    if (!hash) {
      navigate(`#${steps[0].title.toLowerCase()?.split(" ")?.join("-")}`);
    }
    
  }, [hash, navigate, state]);

  React.useEffect(() => {
    if (hash) {
      const index = steps.findIndex(
        (step) =>
          step.title.toLowerCase()?.split(" ")?.join("-") ===
          hash?.substring(1).toLowerCase()
      );
      setCurrent(index);
    }
  }, [hash]);

  const next = () => {
    navigate(
      `#${steps[current + 1].title.toLowerCase()?.split(" ")?.join("-")}`
    );
  };

  const prev = () => {
    navigate(
      `#${steps[current - 1].title.toLowerCase()?.split(" ")?.join("-")}`
    );
  };

  useHead({
    title: `${steps[current].title} | OpticalAI`,
    description: `Configure ${steps[current].title} for OpticalAI`,
  })

  const done = () => {
    message.success("Workflow Configured Successfully");
    if (workflowName === undefined) return navigate("/");
    return navigate(`/workflows/${workflowName}-${pathname.split("/").pop()}`);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    lineHeight: "20px",
    textAlign: "start",
    color: token?.colorTextTertiary,
    backgroundColor: token?.colorFillAlter,
    borderRadius: token?.borderRadiusLG,
    border: `1px dashed ${token?.colorBorder}`,
    marginTop: 20,
    overflow: "scroll",
  };

  const workflow_name = `Configurations/${workflowName}`;

  return (
    <div>
      <Headnav heading={workflow_name} />
      <div className="my-[18px] px-[20px]">
        <Steps
          current={current}
          size="small"
          className="font-popins"
          labelPlacement="horizontal"
          items={items}
        />
        <div
          style={contentStyle}
          className="h-[calc(100vh-200px)] !font-popins"
        >
          {steps[current].content}
        </div>
        <div className="my-5">
        {current > 0 && (
            <Button
              size="middle"
              style={{ margin: "0 8px" }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" size="middle" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" size="middle" onClick={done}>
              Done
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkflowConfig;
