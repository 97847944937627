import {
  GripVertical,
  ChevronDown,
  MoreVertical,
  Pencil,
  Trash2,
  Check,
  Table,
} from "lucide-react";
import { Transition } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import { Input, InputRef, Radio, Select, Spin, Empty } from "antd";
import { useQuery } from "@tanstack/react-query";
import { cn } from "@/lib/utils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { useApprover,StageTypes as IStage } from "../hooks/useApprover";

interface StageTypes {
  stage: IStage,
  onToggle: (id: number) => void;
  removeStage: (id: number) => void;
  onEditName: (id: number, name: string) => void;
  enableEditing: (id: number) => void;
  onSelectFlagCondition: (id: number, value: number) => void;
  onSelectReviewer: (id: number, value: string) => void;
  onOpenReviewer: (id: number) => void;
  onSelectAppliedTo: (id: number, value: string) => void;
  onOpenAppliedToModal: (id: number) => void;
  onSelectCondition: (id: number, value: string) => void;
}

const Stage = ({
  stage,
  onToggle,
  removeStage,
  onEditName,
  enableEditing,
  onSelectFlagCondition,
  onSelectReviewer,
  onOpenReviewer,
  onSelectAppliedTo,
  onOpenAppliedToModal,
  onSelectCondition
}: StageTypes) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: stage.id });

  const { fetchApprovers, fetchApplied } = useApprover();
  const location = useLocation();

  const { data, isPending } = useQuery({
    queryKey: ["approvers"],
    queryFn: fetchApprovers,
    enabled: !!location.pathname,
  });

  const { data: appliedData, isPending: loadingApplied } = useQuery({
    queryKey: ["applied", location.pathname.split("/").pop()],
    queryFn: () => fetchApplied(location.pathname.split("/").pop() as string),
    enabled: !!location.pathname,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const inputRef = React.useRef<InputRef>(null);

  React.useEffect(() => {
    if (stage.isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [stage.isEditing]);

  return (
    <div
      className="bg-white rounded-md shadow-sm border"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className="flex items-center justify-between mt-1">
        <div className="flex items-center w-full justify-center border-b rounded-none px-[10px] py-[8px]">
          <GripVertical
            size={16}
            className="text-gray-500 mr-2 cursor-grab active:cursor-grabbing"
            {...listeners}
          />
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-[5px]">
              <p className="text-gray-600">
                {stage.isEditing ? (
                  <div className="flex items-center gap-[8px]">
                    <Input
                      autoFocus
                      ref={inputRef}
                      value={stage.name}
                      onChange={(e) => onEditName(stage.id, e.target.value)}
                      onPressEnter={() => enableEditing(stage.id)}
                    />
                    <Check
                      strokeWidth={1.2}
                      size={28}
                      className="py-[0.5px] px-[3px] rounded-full bg-blue-50 text-gray-600 cursor-pointer"
                      onClick={() => enableEditing(stage.id)}
                    />
                  </div>
                ) : (
                  stage.name
                )}
              </p>
            </div>
            <div className="flex items-center">
              <ChevronDown
                size={27}
                className={cn(
                  "text-gray-500 duration-300 transition active:scale-105 mr-2 hover:bg-gray-100 p-1",
                  !stage.open
                    ? "rotate-0 rounded-full"
                    : "rotate-180 rounded-full"
                )}
                onClick={() => onToggle(stage.id)}
              />
              <div className="h-[25px] bg-gray-400 w-[1.5px]" />
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <MoreVertical strokeWidth={1.2} size={20} className="ml-2" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onClick={() => {
                      inputRef.current?.focus();
                      enableEditing(stage.id);
                    }}
                  >
                    <Pencil className="mr-2" strokeWidth={1.2} size={20} />
                    Rename stage
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => removeStage(stage.id)}>
                    <Trash2 className="mr-2" strokeWidth={1.2} size={20} />
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
      <Transition
        show={stage.open}
        enter="transition duration-200 ease-in-out transform"
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
      >
        <div className="px-[20px] py-[10px]">
          <div className="w-full flex items-center justify-between">
            <Radio.Group
              className="w-full flex items-center justify-between gap-[2px]"
              onChange={(e) => {
                onSelectFlagCondition(stage.id, e.target.value);
              }}
              value={stage.flagCondition}
            >
              <Radio value={1} className="text-[11.5px]">
                Assign approver only if invoice is flagged
              </Radio>
              <Radio value={2} className="text-[11.5px]">
                Mandatory review (even if not flagged)
              </Radio>
            </Radio.Group>
          </div>
          <div className="flex items-center mt-4 gap-[10px]">
            <label className="text-gray-500 text-sm w-[70px]">Reviewer</label>
            <Popover open={stage.openReviewer}>
              <PopoverTrigger
                className="flex items-center justify-between w-full"
                value={stage.reviewer}
                onClick={() => onOpenReviewer(stage.id)}
              >
                <Input
                  role="combobox"
                  placeholder="Select approver"
                  value={stage.reviewer as string}
                  defaultValue={stage.reviewer}
                />
              </PopoverTrigger>
              <PopoverContent className="w-[445px] p-0 text-[14px]">
                <Command>
                  <CommandInput
                    className="text-[12px]"
                    placeholder="Search approvers"
                  />
                  <CommandEmpty className="text-[13px] text-center py-2 text-gray-600">
                    No approver found.
                  </CommandEmpty>
                  <CommandList>
                    <CommandGroup>
                      {isPending ? (
                        <Spin tip="Loading..." size="small" />
                      ) : data?.data.length === 0 ? (
                        <Empty description="Add approver and manger to workspace" />
                      ) : (
                        data?.data.map((reviewer) => (
                          <CommandItem
                            key={reviewer._id}
                            value={reviewer.email}
                            className="text-[12px]"
                            onSelect={() =>
                              onSelectReviewer(stage.id, reviewer.email)
                            }
                          >
                            <div>
                              <p className="text-gray-800 capitalize">
                                {reviewer.name}
                              </p>
                              <div className="text-[11px] text-gray-600">
                                {reviewer.email}
                              </div>
                            </div>
                          </CommandItem>
                        ))
                      )}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
          <div className=" mt-3 flex items-center justify-between">
            <label className="text-gray-500 text-sm w-[80px]">Apply to</label>
            <Popover open={stage.openFlagCondition}>
              <PopoverTrigger
                className="flex items-center justify-between w-full"
                onClick={() => onOpenAppliedToModal(stage.id)}
              >
                <Input
                  role="combobox"
                  placeholder="Which field do you want to apply a rule to? (e.g., Invoice number)"
                  value={stage.appliedTo}
                />
              </PopoverTrigger>
              <PopoverContent className="w-[445px] p-0 text-[14px]">
                <Command>
                  <CommandInput
                    className="text-[12px]"
                    placeholder="Search fields"
                  />
                  <CommandEmpty className="text-[13px] text-center py-2 text-gray-600">
                    No field found.
                  </CommandEmpty>
                  <CommandList>
                    <CommandGroup>
                      {loadingApplied ? (
                        <Spin size="small" tip="Loading..." />
                      ) : appliedData?.data.length === 0 ? (
                        <Empty description="Setup the schema first to setup approval stage" />
                      ) : (
                        appliedData?.data.map((item) => (
                          <CommandItem
                            key={item.name}
                            value={item.name}
                            onSelect={() => {
                              onSelectAppliedTo(stage.id, item.name);
                            }}
                            className="text-[12px]"
                          >
                            <div className="w-full">
                              <p className="text-gray-800 capitalize flex items-center justify-between w-full">
                                {item.name}
                                {item.line_item && (
                                  <Table
                                    size={16}
                                    className="text-gray-500"
                                    strokeWidth={1.2}
                                  />
                                )}
                              </p>
                              <div className="text-[11px] text-gray-600">
                                {item.desc}
                              </div>
                            </div>
                          </CommandItem>
                        ))
                      )}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>

          <div className="flex my-[6px] items-center">
            <label className="text-gray-500 text-sm w-[80px]">Condition</label>
            <Select
              placeholder="Select a condition"
              allowClear
              className="w-full mt-1"
              value={stage.condition}
              onChange={(value) => onSelectCondition(stage.id, value as string)}
            >
              <Select.Option value="Is date valid">
                Is date valid ?
              </Select.Option>
              <Select.Option value="Is not a future date">
                Is not a future date ?
              </Select.Option>
              <Select.Option value="Field not exist">Field not exist</Select.Option>
            </Select>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Stage;
