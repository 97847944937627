import {
  // BadgeHelp,
  ArrowLeft,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

// import Theme from "../Theme";

import opticalAilogo from "@/assets/opticalai.png";

import storage from "@/utils/storage";
import { WORKFLOW_NAME } from "@/constant/index";

interface Data {
  tooltipTitle?: string;
  icon: React.ReactNode;
  height?: number;
  width?: number;
}

const data: Data[] = [
  // {
  //   tooltipTitle: "Help",
  //   icon: <BadgeHelp strokeWidth={1.2} />,
  // },
];

const WorkflowGroundHeader = () => {
  const workflow_name = storage.get(WORKFLOW_NAME);
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between h-[60px] px-[20px] font-popins border-b">
      <p className="text-[#343434] dark:text-white flex gap-[10px] items-center">
        <ArrowLeft
          className="cursor-pointer hover:-translate-x-1 animate-in transition"
          strokeWidth={1.5}
          size={22}
          onClick={() => navigate("/workflows/" + workflow_name)}
        />
        <img src={opticalAilogo} className="h-[40px] w-[40px]" />
        OpticalAI ground
      </p>
      <div className="flex items-center gap-[30px]">
        {data &&
          data.map((item, index) => {
            return (
              <TooltipProvider key={index} delayDuration={500}>
                <Tooltip>
                  <TooltipTrigger>
                    <div>{item.icon}</div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{item.tooltipTitle}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            );
          })}
        {/* <Theme /> */}
      </div>
    </div>
  );
};

export default WorkflowGroundHeader;
