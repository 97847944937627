/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosError, CancelTokenSource } from "axios";
import axiosRetry from "axios-retry";

import storage from "@/utils/storage";
import { TOKENS } from "@/constant";

const http: AxiosInstance = axios.create({
  baseURL:
    (import.meta.env.VITE_REST_API_URL as string) ||
    "https://sapi.getopticalai.com/api",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin":
      (import.meta.env.VITE_REST_API_URL as string) ||
      "https://sapi.getopticalai.com",
  },
});

axiosRetry(http, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay || 2000,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    return (
      error.code === "ECONNABORTED" ||
      error.code === "ECONNREFUSED" ||
      error.code === "ERR_NETWORK" ||
      error.code === "ECONNRESET" ||
      error.response?.status === 502
    );
  }
});

http.interceptors.request.use(
  (config) => {
    const store = storage.get(TOKENS)?.access_token;
    if (store && config.headers) {
      config.headers.Authorization = `Bearer ${store}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export const createCancelToken = (): CancelTokenSource => {
  return axios.CancelToken.source();
};

export default http;
