/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { AxiosError } from "axios";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  message,
  // Popover,
  Button,
} from "antd";
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import useWorkflowSchema from "../hooks/useWorkflowSchema";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import { DataTypes } from "./Datatype";

import { MdOutlineLabel } from "react-icons/md";
import { IoPin } from "react-icons/io5";
import {
  AlertTriangle,
  //  BadgeInfo
} from "lucide-react";

const datatypes = [
  {
    value: "date",
    label: "Date",
  },
  {
    value: "string",
    label: "string",
  },
  {
    value: "integer",
    label: "Integer",
  },
];

const dateFormats = [
  {
    value: "YYYY-MM-DD",
    label: "YYYY-MM-DD",
  },
  {
    value: "YYYY/MM/DD",
    label: "YYYY/MM/DD",
  },
  {
    value: "MM-DD-YYYY",
    label: "MM-DD-YYYY",
  },
  {
    value: "DD/MM/YYYY",
    label: "DD/MM/YYYY",
  },
];

interface Props {
  showNewField: boolean;
  setShowNewField: React.Dispatch<React.SetStateAction<boolean>>;
  section_name: string;
  refetch: () => void;
}

interface FormData {
  label: string;
  desc: string;
  id: string;
  confidence_level: number;
  is_required?: boolean;
  data_type: string;
  date_format?: string | null;
  question: string;
}

const AddNewField = ({
  setShowNewField,
  showNewField,
  section_name,
  refetch,
}: Props) => {
  const { addWorkflowField } = useWorkflowSchema();

  const { pathname } = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      confidence_level: 70,
      desc: "",
      label: "",
      id: "",
      is_required: false,
      data_type: "",
      date_format: null as string | null,
      question: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(
      Yup.object().shape({
        label: Yup.string().required("Label is required"),
        desc: Yup.string().required("Description is required"),
        confidence_level: Yup.number().required("Confidence level is required"),
        id: Yup.string()
          .required("ID is required")
          .matches(/^[a-zA-Z_]\w+$/, {
            message:
              "ID can't start with a number and can't contain special characters and spaces and should be snake_case.",
          }),
        is_required: Yup.boolean().optional(),
        data_type: Yup.string().required("Data type is required"),
        date_format: Yup.string().nullable(),
        question: Yup.string().required("Question is required"),
      })
    ),
  });

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["addWorkflowField"],
    mutationFn: addWorkflowField,
    onError: (err: AxiosError) => {
      const errorMessage = (err.response?.data as { message: string })?.message;
      message.error(errorMessage);
    },
    onSuccess: (data) => {
      message.success(data.data.message);
      refetch();
      setShowNewField(false);
      reset();
    },
  });

  const onSubmit = (data: FormData) => {
    mutateAsync({
      workflowId: pathname.split("/").pop() || "",
      data: {
        section: section_name,
        is_visible: true,
        description: data.desc,
        id: data.id,
        label: data.label,
        data_type: data?.data_type,
        question: data.question,
        date_format: data.date_format as string,
      },
    });
  };

  return (
    <Sheet open={showNewField} onOpenChange={() => setShowNewField(false)}>
      <SheetContent className="min-w-[450px]">
        <SheetHeader>
          <SheetTitle>
            Add new field to {section_name?.split("_")?.join(" ")}
          </SheetTitle>
        </SheetHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-[10px] mt-[10px]">
            <div>
              <Label className="text-gray-600 text-[13px] dark:text-gray-200">
                ID
              </Label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    className="w-full mt-[5px]"
                    name="id"
                    placeholder="Enter ID"
                    error={errors.id?.message}
                    addornment={<IoPin className="h-[20px]" />}
                  />
                )}
                name="id"
              />
            </div>
            <div>
              <Label className="text-gray-600 text-[13px] dark:text-gray-200">
                Label
              </Label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    className="w-full mt-[5px]"
                    placeholder="Enter label"
                    name="label"
                    error={errors.label?.message}
                    addornment={<MdOutlineLabel className="h-[20px]" />}
                  />
                )}
                name="label"
              />
            </div>
            {/* <div>
              <Label className="text-gray-600 text-[13px] dark:text-gray-200 flex justify-between items-center">
                Ask *
                <Popover
                  content={
                    <div>
                      Ask is the question that you want to ask the AI to fill
                      the field. <br />
                      This will help the AI to understand what you want from the
                      document.
                      <br /> <br />
                      <b>Best practice to write the perfect question</b> <br />
                      1. Be specific <br />
                      2. Be clear <br />
                      3. Try small sentance <br />
                      <br />
                      exmple 1: What is the invoice number ? <br />
                      example 2: What is the total amount ? <br />
                    </div>
                  }
                  title="Ask ?"
                  trigger={"hover"}
                >
                  <BadgeInfo size={15} strokeWidth={1.2} className="cursor-pointer" />
                </Popover>
              </Label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="like What is the invoice number ?"
                    className="mt-2"
                    name="question"
                  />
                )}
                name="question"
              />
              {errors.question && (
                <p className="text-[12px] text-red-500 dark:text-red-500 flex items-center gap-1 mt-[5px]">
                  <AlertTriangle size={13} />
                  {errors.question.message}
                </p>
              )}
            </div> 
            */}
            <div>
              <Label className="text-gray-600 text-[13px] dark:text-gray-200">
                Description
              </Label>
              <Controller
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    placeholder="short description of the field"
                    className="flex min-h-[100px] mt-2 !text-[13px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 max-h-[150px]"
                    name="desc"
                  />
                )}
                name="desc"
              />
              {errors.desc && (
                <p className="text-[12px] text-red-500 dark:text-red-500 flex items-center gap-1 mt-[5px]">
                  <AlertTriangle size={13} />
                  {errors.desc.message}
                </p>
              )}
            </div>
            <div>
              <Controller
                control={control}
                name="data_type"
                render={({ field }) => (
                  <DataTypes
                    comp_name="date type"
                    data={datatypes}
                    {...field}
                  />
                )}
              />
              {errors.data_type && (
                <p className="text-[12px] text-red-500 dark:text-red-500 flex items-center gap-1 mt-[5px]">
                  <AlertTriangle size={13} />
                  {errors.data_type.message}
                </p>
              )}
            </div>
            <div>
              {watch("data_type") === "date" && (
                <Controller
                  control={control}
                  name="date_format"
                  render={({ field }) => (
                    <DataTypes
                      comp_name="date format"
                      data={dateFormats}
                      {...field}
                    />
                  )}
                />
              )}
            </div>
          </div>
          <div className="my-[20px] flex gap-[10px]">
            <Button
              className="w-[90px]"
              type="primary"
              loading={isPending}
              htmlType="submit"
            >
              Create
            </Button>
          </div>
        </form>
      </SheetContent>
    </Sheet>
  );
};

export default AddNewField;
