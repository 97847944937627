import React from "react";
import { Button, Empty, Typography } from "antd";

const GL_Codes: React.FC = () => {
  return (
    <div className="flex  items-center justify-center h-[calc(100vh-300px)]">
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{ height: 200 }}
        className="flex flex-col items-center justify-center"
        description={
          <Typography.Text>Upload GL code sheet to get started</Typography.Text>
        }
      >
       <div className="flex items-center gap-2">
       <Button type="primary">Upload sheet</Button>
       <Button type="default">Integrate API</Button>
       </div>
      </Empty>
    </div>
  );
};

export default GL_Codes;
