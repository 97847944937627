import React from "react";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError, CancelTokenSource } from "axios";
import { createCancelToken } from "@/http";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

import useWorkflowSchema from "../hooks/useWorkflowSchema";

interface data {
  id: string;
  section: string;
  workflowId: string;
}

interface DeleteFieldProps {
  setCloseDialog: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: boolean;
  data: data;
  refetch: () => void;
}

const DeleteField = ({
  closeDialog,
  setCloseDialog,
  data,
  refetch,
}: DeleteFieldProps) => {
  const cancelTokenRef = React.useRef<CancelTokenSource | null>(null);

  const { deleteWorkflowField } = useWorkflowSchema();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["deleteWorkflowField"],
    mutationFn: ({
      id,
      section,
      workflowId,
    }: {
      id: string;
      section: string;
      workflowId: string;
    }) => {
      cancelTokenRef.current = createCancelToken();
      return deleteWorkflowField(
        { id, section, workflowId },
        cancelTokenRef.current
      );
    },
    onSuccess: (data) => {
      refetch();
      setCloseDialog(false);
      message.success(data.data.message);
    },
    onError: (error) => {
      return message.error(
        ((error as AxiosError).response?.data as { message: string }).message);
    },
  });

  const onDelete = async () => {
    await mutateAsync({
      id: data.id,
      section: data.section,
      workflowId: data.workflowId,
    });
    setCloseDialog(false);
    refetch();
  };

  const handleCancel = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("canceled");
    }
    setCloseDialog(false);
  };

  return (
    <div>
      <AlertDialog
        open={closeDialog}
        key={data.id}
        onOpenChange={() => setCloseDialog(false)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription className="">
              This action cannot be undone. This will permanently delete the{" "}
              <div className="flex gap-[3px]">
                <div className="flex gap-[2px]">
                  <span className="text-gray-800">{data.id}</span>
                </div>
                from the{" "}
                <div className="flex gap-[2px]">
                  <span className="text-gray-800">{data.section}</span>
                </div>{" "}
                section.
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancel}>Cancel</AlertDialogCancel>
            <Button
              loading={isPending}
              onClick={onDelete}
              className="w-[100px] !bg-red-500 hover:!bg-red-600"
            >
              Continue
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default DeleteField;
