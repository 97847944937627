import * as React from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { Label } from "@/components/ui/label";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";



interface DataTypesProps {
  value: string | null | undefined;
  onChange: (value: string | null) => void;
  data: { label: string; value: string | null }[];
  comp_name: string;
}

export function DataTypes({ onChange, value, data ,comp_name}: DataTypesProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Label className="text-gray-600 text-[13px] dark:text-gray-200 mt-1">{comp_name}</Label>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between mt-2"
        >
          {value || `Select ${comp_name} type`}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command>
          <CommandInput placeholder={`Search ${comp_name} type`} className="h-9" />
          <CommandEmpty>No {comp_name} type found.</CommandEmpty>
          <CommandGroup>
            {data.map((item) => (
              <CommandItem
                key={item.value}
                onSelect={() => {
                  onChange(item.value);
                  setOpen(false);
                }}
                className={cn(
                  "flex items-center justify-between px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-800",
                  value === item.value && "bg-gray-100 dark:bg-gray-800"
                )}
              >
                {item.label}
                {value === item.value && <CheckIcon className="h-4 w-4" />}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}