import { message, Upload } from "antd";
import type { UploadProps } from "antd";

import { useToken } from "@/providers/useAuthProvider";

interface IUploadFile {
  workflow_id: string;
  workflow_name: string;
  document_type: string;
  fileList: UploadProps["fileList"];
  setFileList: (fileList: UploadProps["fileList"]) => void;
}

const useUploadFile = ({
  document_type,
  workflow_id,
  workflow_name,
  fileList,
  setFileList,
}: IUploadFile) => {
  const { access_token } = useToken();

  const formData = new FormData();
  formData.append("workflow_id", workflow_id);
  formData.append("workflow_name", workflow_name);
  formData.append("document_type", document_type);

  const uploadFileProps: UploadProps = {
    name: "docs",
    action:
      (import.meta.env.VITE_REST_API_URL as string) + "/files/up/docs" ||
      "https://sapi.getopticalai.com/api" + "/files/up/docs",
    headers: {
      authorization: `Bearer ${access_token}`,
    },
    maxCount: 1,
    data: Object.fromEntries(formData),
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";
      const isPDF = file.type === "application/pdf";
      const isJPEG = file.type === "image/jpeg";
      const isJPG = file.type === "image/jpg";
      if (!isPNG && !isPDF && !isJPEG && !isJPG) {
        message.error(
          "Invalid file format. Only PNG, PDF, JPEG, JPG files are allowed"
        );
        return Upload.LIST_IGNORE;
      }
      // size limit is 5 MB
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image or PDF must smaller than 5MB!");
        return Upload.LIST_IGNORE;
      }
    },
    accept: ".png,.pdf,.jpeg,.jpg",
    onChange(info) {
      let newFileList = [...info.fileList];
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(info.file.response.message);
        newFileList = [];
      } else if (info.file.status === "error") {
        info.fileList = [];
        message.error(info.file.response.message);
      }
      setFileList(newFileList);
    },
    fileList,
  };

  return {
    uploadFileProps,
  };
};

export default useUploadFile;
