/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Empty,Pagination } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Loader2 } from "lucide-react";

import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { ConfigWorkflowDataColumns } from "./colums/ConfigWorkflowsColumn";

import { useNewWorkflow } from "./hooks/useWorkflow";

import workflowEmpty from '@/assets/svgs/no-file.svg'

export function ConfigWorkflowsTable() {
  const { fetchWorkflow } = useNewWorkflow();

  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const { data, isLoading } = useQuery({
    queryKey: ["workflow", page, pageSize],
    queryFn: () => fetchWorkflow({ page, limit: pageSize }),
  });

  React.useEffect(() => {
    setPage(page);
  }, [page, pageSize]);

  /* ---- Table Update Functions ----*/

  const table = useReactTable({
    data: data?.data.data ?? [],
    manualPagination: true,
    columns: [...ConfigWorkflowDataColumns],
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="relative h-[calc(100vh-72px)]">
      {!isLoading ? (
        <div className="px-4">
          <div className="flex items-center py-4 justify-between">
            <Input
              placeholder="Filter workflows with their name..."
              value={
                (table
                  .getColumn("workflow_name")
                  ?.getFilterValue() as string) ?? ""
              }
              onChange={(event) =>
                table
                  .getColumn("workflow_name")
                  ?.setFilterValue(event.target.value)
              }
              className="min-w-[500px] w-full"
            />
          </div>
          <div className="rounded-md border max-h-[700px] overflow-scroll">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table
                    .getRowModel()
                    .rows.sort((a, b) => {
                      const a_date = new Date(a.original.createdAt ?? "");
                      const b_date = new Date(b.original.createdAt ?? "");
                      return b_date.getTime() - a_date.getTime();
                    })
                    .map((row) => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(
                                `${cell.row.original.workflow_id}`,
                                {
                                  state: {
                                    workflow_name:
                                      cell.row.original.workflow_name,
                                  },
                                }
                              );
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={ConfigWorkflowDataColumns.length + 1}
                      className="h-24 text-center"
                    >
                     <Empty
                        className="font-popins flex items-center justify-center flex-col py-10"
                        imageStyle={{ height: 150 }}
                        description="No workflows found!!"
                        image={workflowEmpty}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex items-center bg-[#fbfcfe] justify-between py-[11.5px] px-[25px] absolute right-0 bottom-0 border-t w-full">
            <div className="flex-1 text-sm text-muted-foreground">
            {table.getFilteredSelectedRowModel().rows.length > 0 && (
                <span className="text-[13px] text-gray-700">
                  {table.getFilteredSelectedRowModel().rows.length} workflow(s) selected
                </span>
              )}
            </div>
            <div className="space-x-2">
              <Pagination 
              current={page+1}
              pageSize={pageSize}
              total={data?.data.pagination.total || 0}
              onChange={(page) => setPage(page-1)}
              showSizeChanger={true}
              onShowSizeChange={(current, size) => {
                setPageSize(size)
                setPage(current-1)
              }}
              showTotal={(total, range) => (
                <span>
                  Showing {range[0]}-{range[1]} of {total} workflows
                </span>
              )}
              showLessItems={true}
              showTitle={true}
              />
            </div>
          </div>
          </div>
      ) : (
        <div className="flex items-center justify-center mt-[40px] gap-[6px]">
          <Loader2 className="animate-spin h-5 w-5 text-gray-400" />
          <p className="text-gray-400">Loading...</p>
        </div>
      )}
    </div>
  );
}
