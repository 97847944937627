import { Ban,Trash2,Send,Archive,Box,Shapes } from "lucide-react";

import {appRoutes} from './paths'

export const inbox_tabs = [
  {
    name: "My mails",
    path: appRoutes.dashboard.INBOX.MY_MAILS,
    icon: <Box size={18} strokeWidth={1.4} className="dark:text-gray-50 fill-inherit group-hover:fill-blue-200/60"/>,
    id: "MY_MAILS"
  },
  {
    name: "All mails",
    path: appRoutes.dashboard.INBOX.ALL_MAILS,
    icon: <Archive size={18} strokeWidth={1.4} className="dark:text-gray-50 fill-inherit group-hover:fill-blue-200/60"/>,
    id: "INBOX"
  },
  {
    name: "Unassigned",
    path: appRoutes.dashboard.INBOX.UNASSIGNED,
    icon: <Shapes size={18} strokeWidth={1.4} className="dark:text-gray-50 fill-inherit group-hover:fill-blue-200/60"/>,
    id: "UNASSIGNED"
  },
  {
    name: "Sent",
    path: appRoutes.dashboard.INBOX.SENT,
    icon: <Send size={18} strokeWidth={1.4} className="dark:text-gray-50 fill-inherit group-hover:fill-blue-200/60"/>,
    id: "SENT"
  },
  {
    name: "Spams",
    path: appRoutes.dashboard.INBOX.SPAMS,
    icon: <Ban size={18} strokeWidth={1.4} className="dark:text-gray-50 fill-inherit group-hover:fill-blue-200/60"/>,
    id: "SPAM"
  },
  {
    name: "Deleted",
    path: appRoutes.dashboard.INBOX.DELETED,
    icon: <Trash2 size={18} strokeWidth={1.4} className="dark:text-gray-50 fill-inherit group-hover:fill-blue-200/60"/>, 
    id: "TRASH"
  },
];
