/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";

import Plain from "@/components/Plain";
import { useToken } from "@/providers/useAuthProvider";
import { useUser } from "@/providers/userProvider";
import useHead from "@/hooks/useHead";
import AppLoader from "@/components/Apploader";
import WorkflowGroundHeader from "@/components/Navbars/WorkflowGround";
import Information from "@/views/workflow-ground/components/Information";
import useWorkflowGround from "@/views/workflow-ground/hooks/useWorkflowGround";
import useGetUser from "@/hooks/useGetUser";
import { appRoutes } from "@/utils/paths";
import storage from "@/utils/storage";
import WorkflowGround from "@/views/workflow-ground";

const invalidConfigError =
  "Schema is not configured. Please configure schema and try again.";

const Dashboard = () => {
  useHead({
    title: "Ground | OpticalAI",
    description: "Workflow for OpticalAI",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { getWorkflowAnalysis, reExtract } = useWorkflowGround();
  const { getUser } = useGetUser();
  const { user } = useUser();
  const token = useToken();
  const reRun = location.state?.re_extract || false;

  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["fetch-workflow-files"],
    });
  }, [location.pathname, queryClient]);

  // State hooks
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [tableData, setTableData] = useState<any>(null);

  const key = location.pathname.split("/")?.pop() || "";

  useEffect(() => {
    if (token.access_token) {
      getUser();
    }
  }, [token.access_token]);

  const fetchData = async () => {
    try {
      if (reRun) {
        // Fetch re-extract data
        setIsLoading(true);
        const reExtractResponse = await reExtract({
          job_id: key,
          workflow_id: location.pathname.split("/")[2].replace(/^[^-]+-/, ""),
        });
        if (reExtractResponse.status === 204) {
          message.error(invalidConfigError);
          return navigate(-1);
        }
        setData(reExtractResponse.data.data);
        setTableData(reExtractResponse.data.table);
        setIsLoading(false);
      } else {
        // Fetch workflow-ground data
        setIsLoading(true);
        const workflowResponse = await getWorkflowAnalysis(
          key,
          location.pathname.split("/")[2].replace(/^[^-]+-/, "")
        );
        if (workflowResponse.status === 204) {
          message.error(invalidConfigError);
          return navigate(-1);
        }
        setData(workflowResponse.data.data);
        setTableData(workflowResponse.data.table);
        setIsLoading(false);
      }
    } catch (err: unknown) {
      console.log((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchWorkflowData = async () => {
    await fetchData();
  };

  useEffect(() => {
    fetchWorkflowData();
    return () => {
      setData(null);
      setTableData(null);
      setIsLoading(false);
    };
  }, [location.pathname.split("/")[2].replace(/^[^-]+-/, "")]);

  useEffect(() => {
    if (reRun) {
      navigate(location.pathname, {
        replace: true,
        state: { ...location.state, re_extract: false },
      });
      // No direct way to remove queries, so handle state as needed
    }
  }, []);

  if (!Object.keys(token ?? {}).length) {
    storage.set("redirect", location.pathname);
    return <Navigate to={appRoutes.auth.INDEX} />;
  }

  return (
    <AppLoader userLoading={!user}>
      <Plain className="bg-[#f6f8fc] dark:bg-background fixed w-full">
        <WorkflowGroundHeader />
      </Plain>
      <Plain className="flex fixed top-[60px] left-0 right-0 bottom-0">
        <Plain className="min-w-[400px] overflow-scroll border-r bg-white z-10 dark:bg-slate-900">
          <Information
            loading={isLoading}
            schemaValues={data}
            lineItems={tableData}
          />
        </Plain>
        <Plain className="bg-white w-full mt-[0px] overflow-scroll dark:bg-background">
          <WorkflowGround />
        </Plain>
      </Plain>
    </AppLoader>
  );
};

export default Dashboard;
