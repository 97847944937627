import http, { createCancelToken } from "@/http";

import { Invoice, Datatype } from "@/types/schema";

interface AddNewField extends Datatype {
  section: string;
}

export default function useWorkflowSchema() {
  const getWorkflowSchema = async (workflowId: string) => {
    return http.get<Invoice>(`/workflow/get-workflow-schema/${workflowId}`);
  };

  const toggleWorkflowField = async ({
    workflowId,
    section,
    id,
  }: {
    workflowId: string;
    section: string;
    id: string;
  }) => {
    return http.put<{ message: string }>(
      `/workflow/toggle-workflow-field/${workflowId}`,
      { section, id }
    );
  };

  const addWorkflowField = async ({
    workflowId,
    data,
  }: {
    workflowId: string;
    data: AddNewField;
  }) => {
    return http.post<{ message: string }>(
      `/workflow/add-workflow-field/${workflowId}`,
      data
    );
  };

  const deleteWorkflowField = async (
    {
      workflowId,
      section,
      id,
    }: {
      workflowId: string;
      section: string;
      id: string;
    },
    cancelToken: ReturnType<typeof createCancelToken>
  ) => {
    return http.delete<{ message: string }>(
      `/workflow/delete-workflow-field/${workflowId}`,
      { data: { section, id }, cancelToken: cancelToken.token }
    );
  };

  const updateWorkflowField = async ({
    workflowId,
    section,
    id,
    data,
  }: {
    workflowId: string;
    section: string;
    id: string;
    data: AddNewField;
  }) => {
    return http.put<{ message: string }>(
      `/workflow/update-workflow-field/${workflowId}`,
      { section, id, data }
    );
  };

  const toggleAllFields = async ({
    workflowId,
    section_name,
    value,
  }: {
    workflowId: string;
    section_name: string;
    value: boolean;
  }) => {
    return http.put<{ message: string }>(
      `/workflow/toggle-all-fields/${workflowId}`,
      { section_name, value }
    );
  };

  const addMoreSection = async ({
    workflow_id,
    section_name,
    field_name
  }: {
    workflow_id: string;
    section_name: string;
    field_name: string;
  }) => {
    return http.post<{ message: string }>(
      `/workflow/add-section/${workflow_id}`,
      { section_name,field_name }
    );
  };

  return {
    getWorkflowSchema,
    toggleWorkflowField,
    addWorkflowField,
    deleteWorkflowField,
    updateWorkflowField,
    toggleAllFields,
    addMoreSection,
  };
}
