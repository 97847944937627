import React from "react";
import { Button, Popover, message } from "antd";
import {
  Send,
  Paperclip,
  Bold,
  Underline,
  Trash2,
  ListOrdered,
  List,
  Italic,
  Undo2,
  Redo2,
  AArrowUp,
  X,
} from "lucide-react";
import { useMutation } from "@tanstack/react-query";

import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import TextAlign from "@tiptap/extension-text-align";
import Placeholder from "@tiptap/extension-placeholder";
import UnderLine from "@tiptap/extension-underline";
import Heading from "@tiptap/extension-heading";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";

import { useReplay, Methods, ReplayMessage } from "@/providers/replayContext";

import { gmailApi } from "../api/gmail";

const extensions = [
  Color.configure({ types: [TextStyle.name, List.name] }),
  OrderedList,
  BulletList,
  TextAlign.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    listItem: false,
  }),
  UnderLine,
  Placeholder.configure({
    placeholder: "Write your message here...",
  }),
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  ListItem,
];

const Editor = ({ refetch }: { refetch?: () => void }) => {
  const editor = useEditor({ extensions });
  const { replayMail, sendMail } = gmailApi();
  const { replayMessage, setReplayMessage } = useReplay();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [headers, setHeaders] = React.useState<{
    address: string;
    subject: string;
  }>({
    address: "",
    subject: "",
  });
  const [attachments, setAttachments] = React.useState<
    {
      filename: string;
      mimeType: string;
      content: string;
    }[]
  >([]);

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["replayMail"],
    mutationFn: (body: string) =>
      replayMail(replayMessage?.messageId || "", body),
    onSuccess: (data) => {
      message.success(`Mail sent successfully ${data.data.message}`);
      refetch && refetch();
    },
  });

  const { mutateAsync: newCompose, isPending: newComposePending } = useMutation(
    {
      mutationKey: ["sendMail"],
      mutationFn: (body: string) =>
        sendMail({
          to: headers.address,
          subject: headers.subject,
          body,
          attachments: attachments.map((attachment) => ({
            filename: attachment.filename,
            mimeType: attachment.mimeType,
            content: attachment.content,
          })),
        }),
      onSuccess: (data) => {
        if (data.data?.id) {
          message.success(`Mail sent successfully`);
        }
        refetch && refetch();
      },
    }
  );

  const onCompose = () => {
    if (headers.address === "" || headers.subject === "") {
      message.error("To and Subject can't be empty");
      return;
    }
    if (editor) {
      newCompose(editor.getText()).then(() => {
        editor.commands.clearContent();
        setReplayMessage({
          ...replayMessage,
          method: Methods.COMPOSE,
          show: false,
        } as ReplayMessage);
      });
    }
  };

  const handleOpenAttachmnets = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      const file = files[0];
      if (file.size > 10000000) {
        message.error("File size should be less than 10 MB");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setAttachments([
          ...attachments,
          {
            filename: file.name,
            mimeType: file.type,
            content: base64String.split(",")[1],
          },
        ]);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      className={
        replayMessage?.method === Methods.COMPOSE
          ? "flex flex-col flex-wrap"
          : "py-[5px] flex flex-col flex-wrap"
      }
    >
      <input
        type="file"
        className="hidden"
        ref={inputRef}
        onChange={handleFileChange}
      />
      {replayMessage?.method === Methods.COMPOSE && (
        <div>
          <div className="bg-gray-100 flex items-center justify-between px-[10px]">
            <p className="text-gray-700 font-[500]">New Message</p>
            <Button
              type="text"
              title="Close"
              onClick={() =>
                setReplayMessage({
                  ...replayMessage,
                  method: Methods.COMPOSE,
                  show: false,
                } as ReplayMessage)
              }
              icon={<X strokeWidth={1.6} size={16} />}
              iconPosition="end"
            />
          </div>
          <div className="py-2 px-[10px] flex border-b gap-[20px]">
            <input
              type="text"
              className="w-full border-b outline-none"
              placeholder="To"
              value={headers.address}
              onChange={(e) =>
                setHeaders({ ...headers, address: e.target.value })
              }
            />
            <input
              type="text"
              className="w-full  border-b outline-none"
              placeholder="Subject"
              value={headers.subject}
              onChange={(e) =>
                setHeaders({ ...headers, subject: e.target.value })
              }
            />
          </div>
        </div>
      )}
      <EditorContent className="px-[10px] py-[5px]" editor={editor} />
      {attachments.length > 0 && (
        <div className="flex gap-2 px-[10px] py-[5px] flex-wrap border-t">
          {attachments.map((attachment, index) => (
            <div
              key={index}
              className="flex items-center gap-2 bg-gray-100 rounded-full pl-2"
            >
              <p className="text-gray-500">{attachment.filename}</p>
              <Button
                type="text"
                icon={<X strokeWidth={1.6} size={16} />}
                onClick={() =>
                  setAttachments(attachments.filter((_, i) => i !== index))
                }
              />
            </div>
          ))}
        </div>
      )}
      <MenuBar
        editor={editor}
        onSend={mutateAsync}
        onCompose={onCompose}
        loading={isPending || newComposePending}
        handleOpenAttachmnets={handleOpenAttachmnets}
      />
    </div>
  );
};

export default Editor;

const MenuBar = ({
  editor,
  loading,
  onSend,
  onCompose,
  handleOpenAttachmnets,
}: {
  editor: ReturnType<typeof useEditor> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSend: (body: string) => Promise<any>;
  onCompose: () => void;
  handleOpenAttachmnets: () => void;
  loading: boolean;
}) => {
  const [heading, setHeading] = React.useState<string>("");
  const { setReplayMessage, replayMessage } = useReplay();
  if (!editor) return null;

  const handleSend = () => {
    if (editor.getText().trim() === "") {
      message.error("Message can't be empty");
      return;
    }
    if (replayMessage?.method === Methods.COMPOSE) {
      onCompose();
    } else {
      onSend(editor.getText()).then(() => {
        editor.commands.clearContent();
        setReplayMessage(null);
      });
    }
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-between border-t pt-[10px] pb-[10px] px-[10px] ">
        <div className="flex items-center">
          <Button
            type="primary"
            className="rounded-full"
            icon={
              <Send strokeWidth={1.2} className="rotate-[40deg]" size={18} />
            }
            iconPosition="end"
            onClick={handleSend}
            loading={loading}
          >
            Send
          </Button>
          <Button
            className="ml-5"
            type="text"
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editor.can().undo()}
            icon={<Undo2 strokeWidth={1.6} size={18} />}
            iconPosition="end"
            title="Undo"
          />
          <Button
            type="text"
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editor.can().redo()}
            icon={<Redo2 strokeWidth={1.6} size={18} />}
            iconPosition="end"
            title="Redo"
          />
          <Button
            type="text"
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive("bold") ? "bg-gray-200 ml-5" : "ml-5"}
            icon={<Bold strokeWidth={1.6} size={18} />}
            iconPosition="end"
            title="Bold"
          />
          <Button
            type="text"
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive("underline") ? "bg-gray-200" : ""}
            icon={<Underline strokeWidth={1.6} size={18} />}
            iconPosition="end"
            title="Underline"
          />
          <Button
            type="text"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive("bulletList") ? "bg-gray-200" : ""}
            icon={<List strokeWidth={1.6} size={18} />}
            iconPosition="end"
            title="Bullet List"
          />
          <Button
            type="text"
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive("orderedList") ? "bg-gray-200" : ""}
            icon={<ListOrdered strokeWidth={1.6} size={18} />}
            iconPosition="end"
            title="Ordered List"
          />
          <Button
            type="text"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive("italic") ? "bg-gray-200" : ""}
            icon={<Italic strokeWidth={1.6} size={18} />}
            iconPosition="end"
            title="Italic"
          />
          <Button
            type="text"
            icon={<Paperclip strokeWidth={1.6} size={18} />}
            iconPosition="end"
            title="Attachment"
            onClick={handleOpenAttachmnets}
          />
          <Popover
            className="border border-gray-200 py-[2px] rounded-md px-[8px] ml-1"
            content={
              <div className="flex flex-col gap-2">
                <Button
                  type="text"
                  onClick={() => {
                    setHeading("Heading 1");
                    editor.chain().focus().toggleHeading({ level: 1 }).run();
                  }}
                  className={
                    editor.isActive("heading", { level: 1 })
                      ? "bg-gray-200"
                      : ""
                  }
                >
                  Heading 1
                </Button>
                <Button
                  type="text"
                  onClick={() => {
                    setHeading("Heading 2");
                    editor.chain().focus().toggleHeading({ level: 2 }).run();
                  }}
                  className={
                    editor.isActive("heading", { level: 2 })
                      ? "bg-gray-200"
                      : ""
                  }
                >
                  Heading 2
                </Button>
                <Button
                  title="Heading 3"
                  type="text"
                  onClick={() => {
                    setHeading("Heading 3");
                    editor.chain().focus().toggleHeading({ level: 3 }).run();
                  }}
                  className={
                    editor.isActive("heading", { level: 3 })
                      ? "bg-gray-200"
                      : ""
                  }
                >
                  Heading 3
                </Button>
              </div>
            }
          >
            <Button
              type="text"
              icon={<AArrowUp strokeWidth={1.6} size={18} />}
              iconPosition="end"
              className={editor.isActive("heading") ? "bg-gray-200" : ""}
            >
              {heading
                ? heading?.charAt(0).toUpperCase() + heading.split(" ")[1]
                : ""}
            </Button>
          </Popover>
        </div>
        <div>
          <Button
            type="text"
            className="hover:!text-red-500"
            title="Delete"
            onClick={() =>
              setReplayMessage({
                ...replayMessage,
                method: Methods.COMPOSE,
                show: false,
              } as ReplayMessage)
            }
            icon={<Trash2 strokeWidth={1.6} size={18} />}
            iconPosition="end"
          />
        </div>
      </div>
    </React.Fragment>
  );
};
