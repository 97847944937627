import Headnav from "@/components/Navbars/Headnav";
import { UsersTable } from "@/components/table/userTable";
import { InviteTable } from "@/components/table/userInviteTable";
import useHead from "@/hooks/useHead";

const UserPage = () => {
  useHead({
    title: "Users | OpticalAI",
    description: "Manage users",
  });

  return (
    <div className="relative">
      <Headnav heading="Users management" />
      <div className="h-full w-full overflow-scroll">
        <UsersTable />
        <div className="py-[20px]">
          <p className="mx-5 pt-5 text-gray-700 dark:text-gray-300 font-semibold">
            Pending invitations
          </p>
          <InviteTable />
        </div>
      </div>
    </div>
  );
};

export default UserPage;
