import React from "react";
import { message, Button } from "antd";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { Transition } from "@headlessui/react";

import useHead from "@/hooks/useHead";

import { Switch } from "@/components/ui/switch";
import Text from "@/components/Text";

import TwoFA from "./components/2FA";

import { useUser,useUserDispatch } from "@/providers/userProvider";


import use2FA from "./hooks/use2FA";
import { IUser } from "@/model/user.model";

const Security = () => {
  useHead({
    title: "Security | OpticalAI",
    description: "Security settings",
  });

  const { disable2FA } = use2FA();
  const { user } = useUser();
  const dispatch = useUserDispatch();

  const [showQR, setShowQR] = React.useState(false);

  const { mutate } = useMutation({
    mutationKey: ["disable2FA"],
    mutationFn: disable2FA,
    onError: (error: AxiosError) => {
      return message.error(
        (error.response?.data as { message: string }).message
      );
    },
    onSuccess: (data) => {
      message.success(data.data?.message);
      dispatch?.setUser({...user as IUser,is_2fa_enabled:!user?.is_2fa_enabled});
    },
  });

  return (
    <div>
      <h1 className="text-xl font-semibold">Security Manager</h1>
      <div className="mt-[20px] flex flex-col gap-[20px]">
        <div className="border p-4 rounded-md">
          <div className="flex justify-between items-center">
            <div>
              <Text className="text-gray-600">Two Factor Authentication</Text>
              <p className="text-[#7c7c7c] text-[12px] mt-1">
                Add an extra layer of security to your account
              </p>
            </div>
            <div className="flex items-center gap-[10px]">
              {
              !user?.is_2fa_enabled && !showQR ? (
                <Button onClick={() => setShowQR(true)} type="dashed">
                  Enable
                </Button>
              ):null}
              <Switch disabled={
                !user?.is_2fa_enabled
              } 
              onClick={()=>mutate()}
              checked={user?.is_2fa_enabled} />
            </div>
          </div>
          <Transition
            show={showQR}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <TwoFA />
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default Security;
