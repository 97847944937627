import React from "react";
import { X } from "lucide-react";

import { BiSolidFilePdf } from "react-icons/bi";
import { FaRegImage } from "react-icons/fa6";
import { IoVideocam } from "react-icons/io5";
import { PiShapesDuotone } from "react-icons/pi";

import { Image } from "antd";

import { Attachment } from "@/types/email.type";

interface Props {
  attachments: Attachment[];
}

const Attachments: React.FC<Props> = ({ attachments }) => {
  const [objectUrl, setObjectUrl] = React.useState({
    url: null as string | null,
    mimeType: null as string | null,
  });

  const [preview, setPreview] = React.useState(false);

  const handleOpenAttachment = (atment: Attachment) => {
    const binaryString = atob(atment.data as string);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: atment.mimeType });
    const url = URL.createObjectURL(blob);

    setObjectUrl({
      mimeType: atment.mimeType,
      url,
    });
    setPreview(true);
  };
  return (
    <div>
      {objectUrl &&
        (objectUrl.mimeType?.includes("image") ? (
          <Image
            src={objectUrl.url as string}
            placeholder={false}
            className="hidden"
            preview={{
              visible: preview,
              destroyOnClose: true,
              closeIcon: <X onClick={() => setPreview(false)} />,
            }}
            width={200}
          />
        ) : (
          <Image
            src={objectUrl.url as string}
            className="rounded-lg !h-[10px] !w-[10px] hidden"
            preview={{
              visible: preview,
              destroyOnClose: true,
              closeIcon: <X onClick={() => setPreview(false)} />,
              toolbarRender: () => null,
              mask: false,
              imageRender: () => (
                <iframe
                  src={objectUrl.url as string}
                  width="90%"
                  height="90%"
                  className="rounded-lg"
                />
              ),
            }}
            width={200}
          />
        ))}
      <div className="flex items-center justify-between">
        <h4 className="font-semibold text-gray-800 text-[13px]">Attachments</h4>
        <p className="text-sm text-blue-600">
          Receive all ({attachments?.length})
        </p>
      </div>
      <div>
        <div className="flex items-center  mt-[10px] flex-wrap gap-2">
          {attachments?.map((attachment, index) => (
            <div
              key={index}
              onClick={() => handleOpenAttachment(attachment)}
              className="flex items-center justify-between gap-[10px] px-[10px] cursor-pointer hover:bg-gray-100 bg-white border rounded-full py-[5px]"
            >
              <div className="flex flex-wrap">
                {attachment?.type === "pdf" ? (
                  <BiSolidFilePdf size={20} className="text-red-500" />
                ) : attachment?.type === "image" ? (
                  <FaRegImage size={20} className="text-blue-500" />
                ) : attachment?.type === "video" ? (
                  <IoVideocam size={20} className="text-green-500" />
                ) : (
                  <PiShapesDuotone size={20} className="text-gray-500" />
                )}
              </div>
              <p className="text-sm text-gray-500 truncate">
                {attachment?.filename}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Attachments;
