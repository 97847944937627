import useHead from "@/hooks/useHead";

const Plan = () => {

  useHead({
    title: "Plan | OpticalAI",
    description: "Plan for OpticalAI"
  });

  return  (
    <div>
        <h1 className="text-xl font-semibold">
        Plan Manager
        </h1>
        <p className="text-[14px] text-[#7c7c7c]">Manage your subscripition plan from here.</p>
        <div className="mt-[50px] text-center">
          <p>Comming soon...</p>
        </div>

    </div>
  )
};

export default Plan;
